import { connect } from "react-redux";
import { EGameStage } from "../../redux/modules/gameTypes";
import { IStoreState } from "../../redux/storeTypes";
import RoomsView from "./Rooms";
import { IMapStateToProps } from "./RoomsTypes";

const mapStateToProps = (state: IStoreState): IMapStateToProps => ({
  locked: state.game.stage !== EGameStage.STOPPED,
});

export default connect(mapStateToProps)(RoomsView);
