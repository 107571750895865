import TWEEN from "@tweenjs/tween.js";
import { Entity } from "ecsy";
import DraggedComponent from "../components/draggedComponent";
import PositionComponent from "../components/positionComponent";
import TweenComponent from "../components/tweenComponent";
import { ICardPosition } from "../types/types";
import { removeComponentForced } from "./ecsy";

export const applyTween = (
  entity: Entity,
  from: any,
  to: any,
  target: any,
  onUpdate: (data: any) => void,
  onComplete: (() => void) | undefined,
  time: number,
  delay: number = 0): void => {

  const tween = new TWEEN.Tween(from);
  tween
    .to(to, time)
    .easing(TWEEN.Easing.Quadratic.Out)
    .onUpdate(onUpdate)
    .onComplete(() => {
      if (onComplete) { onComplete(); }
      removeComponentForced(entity, TweenComponent);
    })
    .onStop(() => {
      if (onComplete) { onComplete(); }
    })
    .delay(delay)
    .start();

  entity.addComponent(TweenComponent, {
    tween,
    data: { ...target },
  });
};

export const cancelTween = (entity: Entity) => {
  const tweenComponent = entity.getComponent(TweenComponent);
  if (tweenComponent && tweenComponent.tween) {
    tweenComponent.tween.stop();
  }
  removeComponentForced(entity, TweenComponent);
};

export const applyTweenWithCancel = (
  entity: Entity,
  from: any,
  to: any,
  onUpdate: (data: any) => void,
  onComplete: (() => void) | undefined,
  time: number,
  delay: number = 0) => {

  cancelTween(entity);
  applyTween(entity, from, to, to, onUpdate, onComplete, time, delay);
};

// :::::::::::::::::::::::: POSITION TWEENING ::::::::::::::::::::::::::::::::::::::::
export const cancelTweenForPosition = (entity: Entity): { currentPosition: ICardPosition, targetPosition: ICardPosition | null } => {
  const tweenComponent = entity.getComponent(TweenComponent);

  const currentPosition = { ...entity.getComponent(PositionComponent) };
  let targetPosition: ICardPosition | null = null;

  if (tweenComponent) {
    targetPosition = {
      x: tweenComponent.data.x,
      y: tweenComponent.data.y,
      angle: tweenComponent.data.angle,
      zIndex: tweenComponent.data.zIndex,
    };

    if (tweenComponent.tween) {
      tweenComponent.tween.stop();
    }
    removeComponentForced(entity, TweenComponent);
  }

  return {
    currentPosition,
    targetPosition,
  };
};

export const applyTweenForPositionWithCancel = (entity: Entity, targetPosition: ICardPosition, time: number, delay: number = 0) => {
  const ct = cancelTweenForPosition(entity);

  const positionComponent = entity.getMutableComponent(PositionComponent);
  positionComponent.zIndex = targetPosition.zIndex;

  applyTween(entity,
    {
      x: ct.currentPosition.x,
      y: ct.currentPosition.y,
      angle: ct.currentPosition.angle,
    },
    {
      x: targetPosition.x,
      y: targetPosition.y,
      angle: targetPosition.angle,
    },
    targetPosition,
    (data) => {
      const p = entity.getMutableComponent(PositionComponent);
      if (p) {
        p.x = data.x;
        p.y = data.y;
        p.angle = data.angle;
      }
    },
    undefined,
    time, delay);
};

export const applyTweenForPositionWithCancelAndDelayedZIndex = (entity: Entity, targetPosition: ICardPosition, time: number, delay: number, onComplete: () => void) => {
  const ct = cancelTweenForPosition(entity);

  const sourceZIndex = ct.currentPosition.zIndex;
  const targetZIndex = targetPosition.zIndex;
  const midZIndex = ((targetZIndex - sourceZIndex) / 2) + sourceZIndex;

  applyTween(entity,
    {
      x: ct.currentPosition.x,
      y: ct.currentPosition.y,
      angle: ct.currentPosition.angle,
      zIndex: ct.currentPosition.zIndex,
    },
    {
      x: targetPosition.x,
      y: targetPosition.y,
      angle: targetPosition.angle,
      zIndex: targetPosition.zIndex,
    },
    targetPosition,
    (data) => {
      const p = entity.getMutableComponent(PositionComponent);
      if (p) {
        p.x = data.x;
        p.y = data.y;
        p.angle = data.angle;
        if (p.zIndex !== targetZIndex) {
          const diff = sourceZIndex < targetZIndex ? data.zIndex - midZIndex : midZIndex - data.zIndex;
          if (diff > 0) {
            p.zIndex = targetZIndex;
          }
        }
      }
    },
    onComplete,
    time, delay);
};

export const applyTweenForDragged = (entity: Entity, from: ICardPosition, targetPosition: ICardPosition, time: number, delay: number = 0) => {
  applyTween(entity,
    { x: from.x, y: from.y, angle: from.angle },
    { x: targetPosition.x, y: targetPosition.y, angle: targetPosition.angle },
    targetPosition,
    (data) => {
      const d = entity.getMutableComponent(DraggedComponent);
      if (d) {
        d.x = data.x;
        d.y = data.y;
        d.angle = data.angle;
      }
    },
    undefined,
    time, delay);
};
