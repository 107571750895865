import { EClientMessageType, IClientMessageMove, IClientMessageStart, IClientMessageStop } from "../commonClientServer/messagesClientTypes";
import { EKind } from "../commonClientServer/settingsTypes";
import { IStore } from "../redux/storeTypes";

export const createMessageGameStartRequest = (store: IStore): IClientMessageStart => {
  const { settings, webSocket } = store.getState();
  return {
    type: EClientMessageType.START,
    payload: {
      difficulty: settings.difficulty,
      spades: settings.spades,
      players: [
        { clientId: webSocket.clientId, kind: EKind.HUMAN },
        { clientId: undefined, kind: settings.player1_kind },
        { clientId: undefined, kind: settings.player2_kind },
        { clientId: undefined, kind: settings.player3_kind },
      ],
      showOppCards: settings.showOppCards,
    },
  };
};

export const createMessageGameMoveRequest = (store: IStore, taking: boolean, cards: string[]): IClientMessageMove => {
  const { webSocket, game } = store.getState();
  return {
    type: EClientMessageType.MOVE,
    payload: {
      gameId: game.gameId!,
      clientId: webSocket.clientId,
      taking,
      cards,
    },
  };
};

export const createMessageGameStopRequest = (store: IStore): IClientMessageStop => {
  const { webSocket, game } = store.getState();
  return {
    type: EClientMessageType.STOP,
    payload: {
      gameId: game.gameId!,
      clientId: webSocket.clientId,
    },
  };
};
