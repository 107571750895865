import { ICardPosition, ICoords, ISize } from "../types/types";

export default class CollocationManager {
  public ctx: any = null;
  public ctxHit: any = null;
  public isMobile: boolean = false;
  public screenWidth: number = 0;
  public screenHeight: number = 0;
  public newScreenSize: boolean = false;

  private ratio: number = 1;
  private cardWidth: number = 76;
  private cardHeight: number = 106;
  private mobileDip: number = 0;

  public init(ctx: any, ctxHit: any, isMobile: boolean, width: number, height: number) {
    // - screen layout: pc, mobile...
    // - screen width & height
    this.ctx = ctx;
    this.isMobile = isMobile;
    this.update(ctxHit, width, height);
  }

  public getRatio() { return this.ratio; }

  public update(ctxHit: any, width: number, height: number) {
    this.ctxHit = ctxHit;

    if (this.screenWidth !== width || this.screenHeight !== height) {
      this.newScreenSize = true;
    }
    this.screenWidth = width;
    this.screenHeight = height;

    let defSizeForRatio1 = 480;
    if (this.isMobile) { defSizeForRatio1 = 320; }

    if (width < height) {
      this.ratio = width / defSizeForRatio1;
    } else {
      this.ratio = height / defSizeForRatio1;
    }
    const defCardWidth = 76;
    const defCardHeight = 106;
    this.cardWidth = defCardWidth * this.ratio;
    this.cardHeight = defCardHeight * this.ratio;

    if (this.isMobile) { this.mobileDip = this.cardHeight / 4; }

    console.log(`cardSize: ${this.cardWidth} x ${this.cardHeight}`);
  }

  public getCardSize(setId: number): ISize {
    return { w: this.cardWidth, h: this.cardHeight };
  }

  public getDraggedPositions(cardsCount: number, phase: number, totalCardsCount: number): ICardPosition[] {
    // phase: 0:player, 1:table
    const positions: ICardPosition[] = [];

    switch (phase) {
      case 0: { // player
        const sw = this.getShiftAndWidthAsRow(totalCardsCount);
        for (let i = 0; i < cardsCount; i++) {
          positions.push({
            x: i * sw.shift,
            y: 0,
            angle: 0,
            zIndex: -1,
          });
        }
        break;
      }
      case 1: { // table
        const shiftX = this.cardWidth / 3;
        for (let i = 0; i < cardsCount; i++) {
          positions.push({
            x: i * shiftX,
            y: 0,
            angle: 0,
            zIndex: -1,
          });
        }
        break;
      }
      default: break;
    }

    return positions;
  }

  /// TABLE
  ///
  public getTableCoordinatesAtCentre(): ICoords {
    return {
      centreX: this.screenWidth / 2,
      centreY: (this.screenHeight / 2) - this.mobileDip,
      angle: 0,
    };
  }

  public getTablePositionsAsRandomPile(cardsNumber: number, randomFactors: any[]): { positions: ICardPosition[], randomFactors: any[] } {
    if (randomFactors.length === 0) {
      for (let i = 0; i < cardsNumber; i++) {
        randomFactors.push({ rndX: Math.random(), rndY: Math.random(), rndAngle: Math.random() });
      }
    }

    const positions: ICardPosition[] = [];
    randomFactors.forEach((factor, index) => {
      positions.push(this.getTablePositionAsRandomPile(factor, index));
    });

    return { positions, randomFactors };
  }

  public getTablePositionAsRandomPile(factor: any, cardIndex: number): ICardPosition {
    const coords = this.getTableCoordinatesAtCentre();

    const shiftX = this.cardWidth / 2;
    const rndShiftX = (factor.rndX * shiftX) - (shiftX / 2);
    const shiftY = this.cardHeight / 2;
    const rndShiftY = (factor.rndY * shiftY) - (shiftY / 2);
    const shiftAngle = Math.PI / 4;
    const rndAngle = coords.angle + ((factor.rndAngle * shiftAngle) - (shiftAngle / 2));

    return {
      x: coords.centreX + rndShiftX,
      y: coords.centreY + rndShiftY,
      angle: rndAngle,
      zIndex: 100 + cardIndex + 1,
    };
  }

  /// PLAYERS
  ///
  public getPlayerCoordinatesAsRow(setId: number): ICoords {
    let angle = 0;
    let centreX = 0;
    let centreY = 0;

    if (this.isMobile) {
      switch (setId) {
        case 1: // main player SOUTH
          angle = 0;
          centreX = this.screenWidth / 2;
          centreY = this.screenHeight - (this.cardHeight / 2);
          break;
        case 2: // opponent WEST
          angle = Math.PI / 2;
          centreX = -this.mobileDip;
          centreY = (this.screenHeight / 2) - this.mobileDip;
          break;
        case 3: // opponent NORTH
          angle = Math.PI;
          centreX = this.screenWidth / 2;
          centreY = -this.mobileDip;
          break;
        case 4: // opponent EAST
          angle = -Math.PI / 2;
          centreX = this.screenWidth + this.mobileDip;
          centreY = (this.screenHeight / 2) - this.mobileDip;
          break;
        default: break;
      }
    } else {
      switch (setId) {
        case 1: // main player SOUTH
          angle = 0;
          centreX = this.screenWidth / 2;
          centreY = this.screenHeight - (this.cardHeight / 2);
          break;
        case 2: // opponent WEST
          angle = Math.PI / 2;
          centreX = this.cardHeight / 2;
          centreY = this.screenHeight / 2;
          break;
        case 3: // opponent NORTH
          angle = Math.PI;
          centreX = this.screenWidth / 2;
          centreY = this.cardHeight / 2;
          break;
        case 4: // opponent EAST
          angle = -Math.PI / 2;
          centreX = this.screenWidth - (this.cardHeight / 2);
          centreY = this.screenHeight / 2;
          break;
        default: break;
      }
    }

    return { centreX, centreY, angle };
  }

  public getPlayerPositionsAsRow(setId: number, cardsNumber: number): ICardPosition[] {
    const sw = this.getShiftAndWidthAsRow(cardsNumber);
    const halfWidth = sw.width / 2;

    const coords = this.getPlayerCoordinatesAsRow(setId);
    const positions = [];
    for (let i = 0; i < cardsNumber; i++) {
      let zIndex = i + 51;
      switch (setId) {
        case 1:
          zIndex += 200;
          positions.push({
            x: (coords.centreX - halfWidth) + (i * sw.shift) + (this.cardWidth / 2),
            y: coords.centreY,
            angle: coords.angle,
            zIndex,
          });
          break;
        case 2:
          positions.push({
            x: coords.centreX,
            y: (coords.centreY - halfWidth) + (i * sw.shift) + (this.cardWidth / 2),
            angle: coords.angle,
            zIndex,
          });
          break;
        case 3:
          positions.push({
            x: (coords.centreX + halfWidth) - (i * sw.shift) - (this.cardWidth / 2),
            y: coords.centreY,
            angle: coords.angle,
            zIndex,
          });
          break;
        case 4:
          positions.push({
            x: coords.centreX,
            y: (coords.centreY + halfWidth) - (i * sw.shift) - (this.cardWidth / 2),
            angle: coords.angle,
            zIndex,
          });
          break;
        default: break;
      }
    }

    return positions;
  }

  /// utils
  public getShiftAndWidthAsRow(cardsNumber: number): { shift: number, width: number } {
    const maxWidth = this.cardWidth * 3.5;

    let shift = this.cardWidth / 3;
    let width = ((cardsNumber - 1) * shift) + this.cardWidth;

    if (width > maxWidth) {
      shift = (maxWidth - this.cardWidth) / (cardsNumber - 1);
      width = maxWidth;
    }

    return { shift, width };
  }

  // setId [0,1,2,3,4] 0-table
  public getAllCardsOutside() {
    // initial positions before gathering them into a pile for dealing process
  }

  public getPreparedForDeal() {
    // all cards positions just before dealing
  }

}
