import React from "react";
import NotificationSystem from "react-notification-system";
import { TComponentProps } from "./NotificationsTypes";

export class Notifications extends React.Component<TComponentProps> {

  public notificationSystemRef = React.createRef<any>();

  public componentDidUpdate = (prevProps: TComponentProps) => {
    const noty : any = this.notificationSystemRef.current as any;

    const { notifications, hideNotification } = this.props;
    const notificationIds = notifications.map((item) => item.uid);
    const systemNotifications = noty.state.notifications || [];

    if (notifications.length > 0) {
      // Get all active notifications from react-notification-system
      /// and remove all where uid is not found in the reducer
      systemNotifications.forEach((notificationItem: any) => {
        if (notificationIds.indexOf(notificationItem.uid) < 0) {
          noty.removeNotification(notificationItem.uid!);
        }
      });

      notifications.forEach((notificationItem) => {
        noty.addNotification({
          ...notificationItem,
          onRemove: () => {
            hideNotification(notificationItem.uid as number);
            if (notificationItem.onRemove) { notificationItem.onRemove(notificationItem); }
          },
        });
      });
    }

    if ((prevProps.notifications !== notifications) && notifications.length === 0) {
      noty.clearNotifications();
    }
  }

  public shouldComponentUpdate(nextProps: TComponentProps) {
    return this.props !== nextProps;
  }

  public render() {
    // const { notificationsList, ...rest } = this.props;

    return (
      <NotificationSystem ref={this.notificationSystemRef} />
    );
  }
}
