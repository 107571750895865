import { Entity, World } from "ecsy";
import InputControlManager from "../ecs/managers/inputControlManager";
import { registerComponent, registerSystem, removeAllEntities } from "./common/ecsy";
import AssetComponent from "./components/assetComponent";
import ButtonComponent from "./components/buttonComponent";
import CardComponent from "./components/cardComponent";
import ClickedComponent from "./components/clickedComponent";
import DraggedComponent from "./components/draggedComponent";
import GameCommandComponent from "./components/gameCommandComponent";
import InteractiveComponent from "./components/interactiveComponent";
import ManagersComponent from "./components/managersComponent";
import PlayerEastComponent from "./components/playerEastComponent";
import PlayerNorthComponent from "./components/playerNorthComponent";
import PlayerSouthComponent from "./components/playerSouthComponent";
import PlayerWestComponent from "./components/playerWestComponent";
import PositionComponent from "./components/positionComponent";
import PositionOriginComponent from "./components/positionOriginComponent";
import SnappedToTargetComponent from "./components/snappedToTargetComponent";
import TableComponent from "./components/tableComponent";
import TableOddsComponent from "./components/tableOddsComponent";
import TweenComponent from "./components/tweenComponent";
import TypeComponent from "./components/typeComponent";
import AssetsManager from "./managers/assetsManager";
import CollocationManager from "./managers/collocationManager";
import GameStateManager from "./managers/gameStateManager";
import DraggedSystem from "./systems/draggedSystem";
import GameStateSystem from "./systems/gameStateSystem";
import InputControlSystem from "./systems/inputControlSystem";
import RendererSystem from "./systems/rendererSystem";
import TweenSystem from "./systems/tweenSystem";

export default class GameWorld {
  private lastTime: number = 0;
  private world: World;
  private managersEntity: Entity;

  constructor(canvas: any, canvasHit: any, assetsManager: AssetsManager, gameStateManager: GameStateManager, isMobile: boolean) {

    const world = new World();

    registerComponent(world, ManagersComponent, "ManagersComponent");
    registerComponent(world, PositionComponent, "PositionComponent");
    registerComponent(world, PositionOriginComponent, "PositionOriginComponent");
    registerComponent(world, ClickedComponent, "ClickedComponent");
    registerComponent(world, DraggedComponent, "DraggedComponent");
    registerComponent(world, SnappedToTargetComponent, "SnappedToTargetComponent");
    registerComponent(world, TweenComponent, "TweenComponent");
    registerComponent(world, PlayerSouthComponent, "PlayerSouthComponent");
    registerComponent(world, PlayerWestComponent, "PlayerWestComponent");
    registerComponent(world, PlayerNorthComponent, "PlayerNorthComponent");
    registerComponent(world, PlayerEastComponent, "PlayerEastComponent");
    registerComponent(world, TableComponent, "TableComponent");
    registerComponent(world, TableOddsComponent, "TableOddsComponent");
    registerComponent(world, ButtonComponent, "ButtonComponent");
    registerComponent(world, CardComponent, "CardComponent");
    registerComponent(world, InteractiveComponent, "InteractiveComponent");
    registerComponent(world, GameCommandComponent, "GameCommandComponent");
    registerComponent(world, AssetComponent, "AssetComponent");
    registerComponent(world, TypeComponent, "TypeComponent");
    registerSystem(world, GameStateSystem, "GameStateSystem");
    registerSystem(world, InputControlSystem, "InputControlSystem");
    registerSystem(world, DraggedSystem, "DraggedSystem");
    registerSystem(world, TweenSystem, "TweenSystem");
    registerSystem(world, RendererSystem, "RendererSystem");

    this.world = world;

    canvasHit.width = canvas.width;
    canvasHit.height = canvas.height;

    const collocationManager = new CollocationManager();
    collocationManager.init(
      canvas.getContext("2d"),
      canvasHit.getContext("2d"),
      isMobile,
      canvas.width,
      canvas.height);

    const inputControlManager = new InputControlManager();

    this.managersEntity = this.world.createEntity()
      .addComponent(ManagersComponent, {
        world,
        collocationManager,
        inputControlManager,
        assetsManager,
        gameStateManager,
      });

    this.lastTime = performance.now();
  }

  public onResize(width: number, height: number, ctxHit: any) {
    this.managersEntity.getComponent(ManagersComponent).collocationManager.update(ctxHit, width, height);
  }

  public onInput(x: number, y: number, pressed: boolean) {
    this.managersEntity.getComponent(ManagersComponent).inputControlManager.setInput(x, y, pressed);
  }

  public step() {
    const time = performance.now();
    const delta = time - this.lastTime;

    // Run all the systems
    this.world.execute(delta, time);

    this.lastTime = time;
  }

  public clear = () => {
    removeAllEntities(this.world);
  }
}
