import { Tween } from "@tweenjs/tween.js";
import { Component } from "ecsy";

export default class TweenComponent extends Component {
  public tween?: Tween;
  public data: any;

  public reset() {
    if (this.tween && this.tween.isPlaying()) {
      this.tween.stop();
    }
    this.tween = undefined;
    this.data = undefined;
  }
}
