import TWEEN from "@tweenjs/tween.js";
import { Entity } from "ecsy";
import * as messagesCache from "../../common/messagesCache";
import { ECacheMessageType, ICacheMessageClear, ICacheMessageDeal, ICacheMessageMove, TCacheMessage } from "../../common/messagesCacheTypes";
import { queryComponents, removeAllEntities } from "../../ecs/common/ecsy";
import { ESortCards, ESpeed } from "../../redux/modules/settingsTypes";
import { ANIM_TIMES, AnimTypes } from "../common/constants";
import * as playerIndicator from "../common/playerIndicator";
import { applyTweenForPositionWithCancel, applyTweenForPositionWithCancelAndDelayedZIndex, applyTweenWithCancel } from "../common/tweenHelper";
import { getPositionsSortedByCard, sortEntities } from "../common/utils";
import AssetComponent from "../components/assetComponent";
import ButtonComponent from "../components/buttonComponent";
import CardComponent from "../components/cardComponent";
import GameCommandComponent from "../components/gameCommandComponent";
import InteractiveComponent from "../components/interactiveComponent";
import ManagersComponent from "../components/managersComponent";
import PlayerEastComponent from "../components/playerEastComponent";
import PlayerNorthComponent from "../components/playerNorthComponent";
import PlayerSouthComponent from "../components/playerSouthComponent";
import PlayerWestComponent from "../components/playerWestComponent";
import PositionComponent from "../components/positionComponent";
import TableComponent from "../components/tableComponent";
import TableOddsComponent from "../components/tableOddsComponent";
import TypeComponent, { ETypes } from "../components/typeComponent";
import { ICardPosition } from "../types/types";

export interface IGameSettings {
  sorting: ESortCards;
  speed: ESpeed;
  showOppCards: boolean;
}

export default class GameStateManager {
  public gameSettings: IGameSettings;
  private currentPlayer: number = -1;

  private serverGameStart: (message: ICacheMessageDeal) => void;
  private serverGameStop: (message: ICacheMessageClear) => void;
  private serverGameMove: (message: ICacheMessageMove) => void;
  private userGameMove: (taking: boolean, cardSymbols: string[]) => void;
  private userGameMoveNextPlayer: (nextPlayer: number) => void;

  constructor(
    gameSettings: IGameSettings,
    serverGameStart: (message: ICacheMessageDeal) => void,
    serverGameStop: (message: ICacheMessageClear) => void,
    serverGameMove: (message: ICacheMessageMove) => void,
    userGameMove: (taking: boolean, cardSymbols: string[]) => void,
    userGameMoveNextPlayer: (nextPlayer: number) => void) {

    this.currentPlayer = -1;
    this.gameSettings = gameSettings;
    this.serverGameStart = serverGameStart;
    this.serverGameStop = serverGameStop;
    this.serverGameMove = serverGameMove;
    this.userGameMove = userGameMove;
    this.userGameMoveNextPlayer = userGameMoveNextPlayer;
  }

  public isUserTurn = (): boolean => this.currentPlayer === 0;

  public getMessage = (): TCacheMessage | null => {
    return messagesCache.getFirstMessage();
  }

  public processMessage = (common: ManagersComponent, message: TCacheMessage) => {
    switch (message.type) {
      case ECacheMessageType.CACHE_MESSAGE_DEAL: {
        this.gameStart(common, message as ICacheMessageDeal);

        const startFromVoidAnimTime = this.getAnimTime(AnimTypes.START_FROM_VOID);
        const startFromVoidAnimDelay = this.getAnimTime(AnimTypes.START_FROM_VOID_DELAY);
        const startDealCardAnimTime = this.getAnimTime(AnimTypes.START_DEAL_CARD);
        const startDealCardAnimDelay = this.getAnimTime(AnimTypes.START_DEAL_CARD_DELAY);
        const startResortAnimTime = this.getAnimTime(AnimTypes.START_RESORT);

        let delay = this.createGameCommandOnBegin(common,
          () => this.gameStartVoidToCentre(common, startFromVoidAnimTime, startFromVoidAnimDelay),
          "gameStartVoidToCentre", startFromVoidAnimTime + startFromVoidAnimDelay);

        delay = this.createGameCommandOnBegin(common,
          () => this.gameStartDeal(common, startDealCardAnimTime, startDealCardAnimDelay),
          "gameStartDeal", startDealCardAnimTime + (24 * startDealCardAnimDelay), delay);

        if (this.gameSettings.sorting !== ESortCards.NONE) {
          this.createGameCommandOnBegin(common,
            () => this.gameStartUserResort(common, startResortAnimTime),
            "gameStartUserResort", startResortAnimTime, delay);
        }

        this.createGameCommandOnEnd(common, () => this.gameStartIndicator(common, message.nextPlayer), "gameStartIndicator", delay);

        this.serverGameStart(message);

        this.updateInteractive(
          queryComponents(common.world, [TableComponent]),
          queryComponents(common.world, [PlayerSouthComponent]),
          message.nextPlayer);
        break;
      }

      case ECacheMessageType.CACHE_MESSAGE_MOVE: {
        const oppMoveAnimTime = this.getAnimTime(AnimTypes.OPP_MOVE_DURATION);
        const oppMoveAnimDelay = this.getAnimTime(AnimTypes.OPP_MOVE_DELAY);
        const oppMovePlayerIndication = this.getAnimTime(AnimTypes.PLAYER_INDICATION);

        if (message.nextPlayer !== -1) {
          this.createGameCommandOnBegin(common, () => this.gameMoveIndicator(
            common,
            message.player,
            message.nextPlayer,
            oppMovePlayerIndication),
            "gameMoveIndicator", oppMoveAnimDelay);
        }

        if (message.player === 0) {
          this.currentPlayer = message.player;
          this.userGameMoveNextPlayer(message.nextPlayer);
          return;
        }

        this.createGameCommandOnBegin(common, () => this.gameMove(common, message as ICacheMessageMove, oppMoveAnimTime), "applyGameMove", oppMoveAnimDelay);
        break;
      }

      case ECacheMessageType.CACHE_MESSAGE_CLEAR: {
        const oppMoveAnimTime = this.getAnimTime(AnimTypes.OPP_MOVE_DURATION);

        this.createGameCommandOnEnd(common, () => this.gameStop(common, message as ICacheMessageClear), "applyGameStop", oppMoveAnimTime);
        this.createGameCommandOnBegin(common, () => this.gameStopIndicator(common), "gameStopIndicator", 0);
        break;
      }

      default:
        console.error(message);
        break;
    }
  }

  // :::::::::::::::::::::::::::::::::::::::::::::::: START ::::::::::::
  public gameStart = (common: ManagersComponent, message: ICacheMessageDeal) => {
    let uniqueIndex = 1;

    const cardSize = common.collocationManager.getCardSize(1);
    message.cards.forEach((cardsSet, playerIndex) => {
      cardsSet.forEach((card, i) => {
        const entity = common.world.createEntity();
        entity
          .addComponent(CardComponent, { id: `card${uniqueIndex}` })
          .addComponent(PositionComponent, {
            x: -cardSize.w * 2,
            y: -cardSize.h * 2,
            angle: 0,
            zIndex: 0,
          });

        const cardComponent = entity.getMutableComponent(CardComponent);
        cardComponent.setFromSymbol(card);
        cardComponent.faceDown();

        switch (playerIndex + 1) {
          case 1:
            entity
              .addComponent(PlayerSouthComponent)
              .addComponent(InteractiveComponent, { colorIndex: uniqueIndex });
            break;
          case 2: entity.addComponent(PlayerWestComponent); break;
          case 3: entity.addComponent(PlayerNorthComponent); break;
          case 4: entity.addComponent(PlayerEastComponent); break;
          default: break;
        }
        uniqueIndex++;
      });
    });

    this.currentPlayer = message.nextPlayer;

    const buttonSortEntity = common.world.createEntity();
    const playerCentrePos = common.collocationManager.getPlayerCoordinatesAsRow(1);
    buttonSortEntity
      .addComponent(ButtonComponent, { name: "sort" })
      .addComponent(AssetComponent, { asset: "button_sort" })
      .addComponent(PositionComponent, {
        x: playerCentrePos.centreX + cardSize.w,
        y: playerCentrePos.centreY - (cardSize.h / 2) - (cardSize.h * 0.2),
        angle: 0,
        zIndex: 0,
      })
      .addComponent(InteractiveComponent, { colorIndex: 100 });
  }

  public gameStartVoidToCentre = (common: ManagersComponent, duration: number, delay: number) => {
    const tableCentre = common.collocationManager.getTableCoordinatesAtCentre();

    const allCardsEntities = queryComponents(common.world, [CardComponent]);

    const voidAngle = (Math.PI * 2) / allCardsEntities.length;
    const radius = (common.collocationManager.screenHeight > common.collocationManager.screenWidth ?
      common.collocationManager.screenHeight :
      common.collocationManager.screenWidth) * 1.5;

    allCardsEntities.forEach((card, index) => {
      const posAngle = -(Math.PI / 2) + (voidAngle * index);
      const positionComponent = card.getMutableComponent(PositionComponent);
      positionComponent.x = (radius * Math.cos(posAngle)) + tableCentre.centreX;
      positionComponent.y = (radius * Math.sin(posAngle)) + tableCentre.centreY;
      positionComponent.angle = -(Math.PI) + (voidAngle * index);
      positionComponent.zIndex = 0;
    });

    const players: Entity[][] = [];
    players.push(queryComponents(common.world, [PlayerSouthComponent]));
    players.push(queryComponents(common.world, [PlayerWestComponent]));
    players.push(queryComponents(common.world, [PlayerNorthComponent]));
    players.push(queryComponents(common.world, [PlayerEastComponent]));

    const tablePositionsData = common.collocationManager.getTablePositionsAsRandomPile(allCardsEntities.length, []);
    tablePositionsData.positions.forEach((position) => position.zIndex += 100);
    let tablePositionIndex = allCardsEntities.length - 1;
    for (let i = 0; i < players[0].length; i++) {
      for (let j = 0; j < players.length; j++) {
        if (players[j].length > 0) {
          applyTweenForPositionWithCancel(
            players[j][i],
            tablePositionsData.positions[tablePositionIndex],
            duration,
            Math.random() * delay);
          tablePositionIndex--;
        }
      }
    }
  }

  public gameStartDeal = (common: ManagersComponent, duration: number, delay: number) => {
    const allCardsEntitiesSorted = sortEntities(queryComponents(common.world, [CardComponent]));

    const players: Entity[][] = [];
    players.push(queryComponents(common.world, [PlayerSouthComponent]));
    players.push(queryComponents(common.world, [PlayerWestComponent]));
    players.push(queryComponents(common.world, [PlayerNorthComponent]));
    players.push(queryComponents(common.world, [PlayerEastComponent]));

    const playersPositions: ICardPosition[][] = [];
    players.forEach((player, index) => {
      playersPositions.push(common.collocationManager.getPlayerPositionsAsRow(index + 1, player.length));
    });

    const positionIndexes: number[] = [0, 0, 0, 0];

    for (let i = 0; i < allCardsEntitiesSorted.length; i++) {
      const entity = allCardsEntitiesSorted[allCardsEntitiesSorted.length - 1 - i].entity;

      let playerIndex = -1;
      if (entity.hasComponent(PlayerSouthComponent)) { playerIndex = 0; } else
        if (entity.hasComponent(PlayerWestComponent)) { playerIndex = 1; } else
          if (entity.hasComponent(PlayerNorthComponent)) { playerIndex = 2; } else
            if (entity.hasComponent(PlayerEastComponent)) { playerIndex = 3; }

      applyTweenForPositionWithCancelAndDelayedZIndex(
        entity,
        playersPositions[playerIndex][positionIndexes[playerIndex]],
        duration,
        delay * i,
        () => {
          if (playerIndex === 0 || this.gameSettings.showOppCards) {
            entity.getMutableComponent(CardComponent).faceUp();
          }
        });
      positionIndexes[playerIndex]++;
    }
  }

  public gameStartUserResort = (common: ManagersComponent, duration: number) => {
    const playerSouthEnitites = queryComponents(common.world, [PlayerSouthComponent]);
    const sortedIndexes = getPositionsSortedByCard(playerSouthEnitites, (this.gameSettings.sorting === ESortCards.ASCENDING));
    const newPlayerPositions = common.collocationManager.getPlayerPositionsAsRow(1, playerSouthEnitites.length);
    playerSouthEnitites.forEach((entity, index) => {
      applyTweenForPositionWithCancel(entity, newPlayerPositions[sortedIndexes[index]], duration);
    });
  }

  // :::::::::::::::::::::::::::::::::::::::::::::::: MOVE :::::::::::::
  public gameMove = (common: ManagersComponent, message: ICacheMessageMove, duration: number) => {
    this.serverGameMove(message);

    const tableEntities = queryComponents(common.world, [TableComponent]);
    let playerEntities: Entity[] = [];
    switch (message.player) {
      case 0: playerEntities = queryComponents(common.world, [PlayerSouthComponent]); break;
      case 1: playerEntities = queryComponents(common.world, [PlayerWestComponent]); break;
      case 2: playerEntities = queryComponents(common.world, [PlayerNorthComponent]); break;
      case 3: playerEntities = queryComponents(common.world, [PlayerEastComponent]); break;
      default: break;
    }

    if (message.player > 0) {
      if (message.taking) {
        // TAKING
        message.cards.forEach((card, index) => {
          const entityIndex = tableEntities.findIndex((entity) => entity.getComponent(CardComponent).symbol === card.toLowerCase());
          const draggedEntity = tableEntities[entityIndex];
          draggedEntity.removeComponent(TableComponent);
          draggedEntity.removeComponent(TableOddsComponent);
          draggedEntity.removeComponent(InteractiveComponent);
          switch (message.player) {
            case 0: draggedEntity.addComponent(PlayerSouthComponent); break;
            case 1: draggedEntity.addComponent(PlayerWestComponent); break;
            case 2: draggedEntity.addComponent(PlayerNorthComponent); break;
            case 3: draggedEntity.addComponent(PlayerEastComponent); break;
            default: break;
          }
        });

        // const southPlayerEntities = rendererSystem.getPlayerSouth();
        const newPositionsPlayer = common.collocationManager.getPlayerPositionsAsRow(message.player + 1, playerEntities.length);
        sortEntities(playerEntities).forEach((item, index) => {
          applyTweenForPositionWithCancelAndDelayedZIndex(item.entity, newPositionsPlayer[index], duration, 0, () => {
            if (!this.gameSettings.showOppCards) { // && southPlayerEntities.length > 0) {
              const cardComponent = item.entity.getMutableComponent(CardComponent);
              if (cardComponent) {
                cardComponent.faceDown();
              }
            }
          });
        });

      } else {
        // PUTTING
        const orgTableLength = tableEntities.length;
        const newPosDataTable = common.collocationManager.getTablePositionsAsRandomPile(orgTableLength + message.cards.length, []);

        message.cards.forEach((card, index) => {
          let entityIndex = playerEntities.findIndex((entity) => entity.getComponent(CardComponent).symbol === card.toLowerCase());
          if (entityIndex === -1) {
            entityIndex = Math.floor(Math.random() * playerEntities.length);
            const cardComponent = playerEntities[entityIndex].getMutableComponent(CardComponent);
            cardComponent.setFromSymbol(card);
          }
          const draggedEntity = playerEntities[entityIndex];
          switch (message.player) {
            case 0: draggedEntity.removeComponent(PlayerSouthComponent); break;
            case 1: draggedEntity.removeComponent(PlayerWestComponent); break;
            case 2: draggedEntity.removeComponent(PlayerNorthComponent); break;
            case 3: draggedEntity.removeComponent(PlayerEastComponent); break;
            default: break;
          }
          draggedEntity.addComponent(TableComponent);
          draggedEntity.addComponent(TableOddsComponent, newPosDataTable.randomFactors[orgTableLength + index]);

          applyTweenForPositionWithCancel(draggedEntity, newPosDataTable.positions[orgTableLength + index], duration);
          draggedEntity.getMutableComponent(CardComponent).faceUp();
        });

        const newPositionsPlayer = common.collocationManager.getPlayerPositionsAsRow(message.player + 1, playerEntities.length);
        sortEntities(playerEntities).forEach((item, index) => {
          applyTweenForPositionWithCancel(item.entity, newPositionsPlayer[index], duration, 0);
        });

      }
    }

    this.updateInteractive(tableEntities, queryComponents(common.world, [PlayerSouthComponent]), message.nextPlayer);

    this.currentPlayer = message.nextPlayer;
  }

  // :::::::::::::::::::::::::::::::::::::::::::::::: STOP :::::::::::::
  public gameStop = (common: ManagersComponent, message: ICacheMessageClear) => {
    this.serverGameStop(message);
    this.currentPlayer = -1;
    if (common.world) {
      removeAllEntities(common.world);
    }
  }

  // :::::::::::::::::::::::::::::::::::::::::::::::: INDICATOR ::::::::
  public gameStartIndicator = (common: ManagersComponent, playerTo: number) => {
    const tableCoords = common.collocationManager.getTableCoordinatesAtCentre();
    const playerCoords = common.collocationManager.getPlayerCoordinatesAsRow(playerTo + 1);
    const cardSize = common.collocationManager.getCardSize(1);

    const angle = playerIndicator.getAngle(tableCoords, playerCoords);
    const pos = playerIndicator.getPosition(angle, cardSize.h, tableCoords);

    const indicatorEntity = common.world.createEntity();
    indicatorEntity
      .addComponent(TypeComponent, { type: ETypes.INDICATOR })
      .addComponent(AssetComponent, { asset: "current_player_indicator" })
      .addComponent(PositionComponent, { ...pos });
  }

  public gameMoveIndicator = (common: ManagersComponent, playerFrom: number, playerTo: number, duration: number) => {
    const typeEntities = queryComponents(common.world, [TypeComponent]);
    const indicatorEntity = typeEntities.find((entity) => entity.getComponent(TypeComponent).type === ETypes.INDICATOR);
    if (indicatorEntity) {
      const tableCoords = common.collocationManager.getTableCoordinatesAtCentre();
      const playerCoordsFrom = common.collocationManager.getPlayerCoordinatesAsRow(playerFrom + 1);
      const playerCoordsTo = common.collocationManager.getPlayerCoordinatesAsRow(playerTo + 1);
      const cardSize = common.collocationManager.getCardSize(1);

      let angleFrom = playerIndicator.getAngle(tableCoords, playerCoordsFrom);
      let angleTo = playerIndicator.getAngle(tableCoords, playerCoordsTo);
      console.log(`angles - from:${angleFrom} to:${angleTo}`);

      const nextPlayerForward = playerIndicator.isNextPlayerForward(playerFrom, playerTo, common.world);
      if (nextPlayerForward) {
        if (angleFrom > angleTo) { angleTo += (2 * Math.PI); }
      } else {
        if (angleFrom < angleTo) { angleFrom += (2 * Math.PI); } else
          if (angleFrom === angleTo) { angleFrom += (2 * Math.PI); }
      }

      applyTweenWithCancel(
        indicatorEntity,
        {
          angle: angleFrom,
        },
        {
          angle: angleTo,
        },
        (data: any) => {
          const pos = playerIndicator.getPosition(data.angle, cardSize.h, tableCoords);
          const positionComponent = indicatorEntity.getMutableComponent(PositionComponent);
          positionComponent.copyFromAny(pos);
        },
        undefined,
        duration,
        0);
    }
  }

  public gameStopIndicator = (common: ManagersComponent) => {
    const typeEntities = queryComponents(common.world, [TypeComponent]);
    const indicatorEntity = typeEntities.find((entity) => entity.getComponent(TypeComponent).type === ETypes.INDICATOR);
    if (indicatorEntity) {
      indicatorEntity.remove();
    }
  }

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  public emitGameMovePut = (common: ManagersComponent, cards: string[]) => {
    this.userGameMove(false, cards);

    const southPlayerEntities = queryComponents(common.world, [PlayerSouthComponent]);
    if (southPlayerEntities.length === 0) {
      // rendererSystem.getCards().forEach((entity: Entity) => entity.getMutableComponent(CardComponent).faceUp());
    }
  }

  public emitGameMoveTake = (cards: string[]) => {
    this.userGameMove(true, cards);
  }

  public getAnimTime = (timeType: number) => {
    switch (this.gameSettings.speed) {
      case ESpeed.SLOW: return ANIM_TIMES[timeType].s;
      case ESpeed.FAST: return ANIM_TIMES[timeType].f;
      default: return ANIM_TIMES[timeType].m;
    }
  }

  private createGameCommandOnBegin = (common: ManagersComponent, action: () => void, message: string, duration: number, delay: number = 0): number => {
    return this.createGameCommand(common, action, undefined, message, duration, delay);
  }

  private createGameCommandOnEnd = (common: ManagersComponent, action: () => void, message: string, duration: number, delay: number = 0): number => {
    return this.createGameCommand(common, undefined, action, message, duration, delay);
  }

  private createGameCommand = (
    common: ManagersComponent,
    actionStart: (() => void) | undefined,
    actionEnd: (() => void) | undefined,
    message: string,
    duration: number,
    delay: number = 0): number => {

    const entity = common.world.createEntity();
    const tween = new TWEEN.Tween({});
    tween
      .to({}, duration)
      .onStart(() => { if (actionStart) { actionStart(); } })
      .onComplete(() => {
        entity.remove();
        if (actionEnd) { actionEnd(); }
      })
      .delay(delay)
      .start();
    entity.addComponent(GameCommandComponent, { tween, message });

    return duration + delay;
  }

  private updateInteractive = (tableEntities: Entity[], playerEntities: Entity[], nextPlayer: number) => {
    let colorIndex = 1;
    const tc = tableEntities.length;
    const interactiveAllowedCount = tc > 1 ? (tc < 4 ? tc - 1 : 3) : 0; // only max last 3 over the 1
    const firstInteractiveIndex = tableEntities.length - interactiveAllowedCount;
    sortEntities(tableEntities).forEach((item, index) => {
      if (index >= firstInteractiveIndex && nextPlayer === 0) {
        if (item.entity.hasComponent(InteractiveComponent)) {
          item.entity.getMutableComponent(InteractiveComponent).colorIndex = colorIndex;
        } else {
          item.entity.addComponent(InteractiveComponent, { colorIndex });
        }
        colorIndex++;
      } else {
        item.entity.removeComponent(InteractiveComponent);
      }
    });

    playerEntities.forEach((entity) => {
      if (entity.hasComponent(InteractiveComponent)) {
        entity.getMutableComponent(InteractiveComponent).colorIndex = colorIndex;
      } else {
        entity.addComponent(InteractiveComponent, { colorIndex });
      }
      colorIndex++;
    });
  }

}
