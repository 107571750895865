import { applyMiddleware, createStore } from "redux";
import ReduxThunk from "redux-thunk";
import rootReducer from "./modules/reducer";

import gameMiddleware from "./middleware/gameMiddleware";
import webSocketMiddleware from "./middleware/webSocketMiddleware";

import { createLogger } from "redux-logger";
import { loadFromStore, saveToStoreMiddleware } from "./middleware/localStorageMiddleware";
import { IStore } from "./storeTypes";

export default function configureStore(): IStore {
  const preloadedState = { ...loadFromStore() };
  const logger = createLogger();

  let middleware = [
    ReduxThunk,
    saveToStoreMiddleware,
    gameMiddleware,
    webSocketMiddleware,
  ];

  if (process.env.NODE_ENV === "development" && process.env.REACT_APP_DISABLE_LOGGER !== "true") {
    console.log("redux-logger added...");
    middleware = [...middleware, logger];
  }

  return createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(
      ...middleware,
    ),
  );
}
