import * as csServerTypes from "../commonClientServer/messagesServerTypes";
import { ECacheMessageType, ICacheMessageClear, ICacheMessageDeal, ICacheMessageMove, TCacheMessage } from "./messagesCacheTypes";

const messages: TCacheMessage[] = [];

export const addMessageToQueue = (message: TCacheMessage) => {
  messages.push(message);
};

export const isEmpty = (): boolean => {
  return (messages.length === 0);
};

export const clearMessages = () => {
  messages.length = 0;
};

export const getFirstMessage = (): TCacheMessage | null => {
  if (messages.length > 0) {
    return messages.shift()!;
  }
  return null;
};

export const convertServerMessage = (message: csServerTypes.TServerMessage): TCacheMessage | null => {
  switch (message.type) {
    case csServerTypes.EServerMessageType.START: {
      const m = message as csServerTypes.IServerMessageStart;
      return {
        type: ECacheMessageType.CACHE_MESSAGE_DEAL,

        gameId: m.payload.gameId,
        difficulty: m.payload.difficulty,
        spades: m.payload.spades,
        showOppCards: m.payload.showOppCards,
        players: m.payload.players.map((player) => ({ clientId: player.clientId, kind: player.kind })),
        cards: m.payload.players.map((player) => player.cards),
        nextPlayer: m.payload.nextPlayer,
      } as ICacheMessageDeal;
    }
    case csServerTypes.EServerMessageType.MOVE: {
      const m = message as csServerTypes.IServerMessageMove;
      return {
        type: ECacheMessageType.CACHE_MESSAGE_MOVE,
        player: m.payload.player,
        taking: m.payload.taking,
        cards: m.payload.cards,
        nextPlayer: m.payload.nextPlayer,
      } as ICacheMessageMove;
    }
    case csServerTypes.EServerMessageType.STOP: {
      const m = message as csServerTypes.IServerMessageStop;
      return {
        type: ECacheMessageType.CACHE_MESSAGE_CLEAR,
        who: m.payload.player,
        why: m.payload.reason,
        losers: m.payload.score ? m.payload.score!.losers : null,
      } as ICacheMessageClear;
    }
    default: break;
  }
  return null;
};

// export const addMessageToQueue = (message: string, dispatchMessage: (message: string) => void) => {
//   messages.push(message);
//   if (!messagesOngoing) {
//     messagesOngoing = true;
//     setTimeout(async () => {
//       while (messages.length > 0) {
//         await (new Promise((resolve) => setTimeout(resolve, MESSAGE_HANDLE_DELAY)));
//         if (messages.length > 0) {
//           const messageTemp = messages.shift()!;
//           dispatchMessage(messageTemp);
//         }
//       }
//       messagesOngoing = false;
//     }, 0);
//   }
// };
