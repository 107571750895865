import { Component } from "ecsy";
import { ICardPosition } from "../types/types";

export default class PositionComponent extends Component {
  public x: number = 0;
  public y: number = 0;
  public angle: number = 0;
  public zIndex: number = 0;

  public copyFromAny(src: ICardPosition) {
    this.x = src.x;
    this.y = src.y;
    this.angle = src.angle;
    this.zIndex = src.zIndex;
  }

  public copy(src: PositionComponent) {
    this.x = src.x;
    this.y = src.y;
    this.angle = src.angle;
    this.zIndex = src.zIndex;
  }

  public reset() {
    this.x = 0;
    this.y = 0;
    this.angle = 0;
    this.zIndex = 0;
  }
}
