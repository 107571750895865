import { Entity } from "ecsy";
import { ESortCards } from "../../redux/modules/settingsTypes";
import { AnimTypes } from "../common/constants";
import { applyTweenForPositionWithCancel } from "../common/tweenHelper";
import ButtonComponent from "../components/buttonComponent";
import CardComponent from "../components/cardComponent";
import ClickedComponent from "../components/clickedComponent";
import CollocationManager from "../managers/collocationManager";
import GameStateManager from "../managers/gameStateManager";
import { getPositionsSortedByCard, sortEntities } from "./utils";

export const handleButtonClick = (
  collocationManager: CollocationManager,
  gameStateManager: GameStateManager,
  interactiveEntity: Entity,
  qrTable: Entity[],
  qrPlayerSouth: Entity[]) => {

  interactiveEntity.addComponent(ClickedComponent);
  const buttonComponent = interactiveEntity.getComponent(ButtonComponent);

  switch (buttonComponent.name) {
    case "sort": {
      if (qrPlayerSouth.length > 1) {
        const orgOrder = sortEntities(qrPlayerSouth)
          .map((item) => item.entity.getComponent(CardComponent).symbol)
          .reduce((acc, cur, index, arr) => acc + cur);

        const resortAnimTime = gameStateManager.getAnimTime(AnimTypes.START_RESORT);

        const sortOrder = gameStateManager.gameSettings.sorting === ESortCards.ASCENDING;
        let sortedIndexes = getPositionsSortedByCard(qrPlayerSouth, sortOrder);
        let newPlayerPositions = collocationManager.getPlayerPositionsAsRow(1, qrPlayerSouth.length);
        qrPlayerSouth.forEach((entity, index) => {
          applyTweenForPositionWithCancel(entity, newPlayerPositions[sortedIndexes[index]], resortAnimTime);
        });

        const newOrder = sortEntities(qrPlayerSouth)
          .map((item) => item.entity.getComponent(CardComponent).symbol)
          .reduce((acc, cur, index, arr) => acc + cur);
        if (newOrder === orgOrder) {
          sortedIndexes = getPositionsSortedByCard(qrPlayerSouth, !sortOrder);
          newPlayerPositions = collocationManager.getPlayerPositionsAsRow(1, qrPlayerSouth.length);
          qrPlayerSouth.forEach((entity, index) => {
            applyTweenForPositionWithCancel(entity, newPlayerPositions[sortedIndexes[index]], resortAnimTime);
          });
        }
      }
      break;
    }
    case "eye": {
      break;
    }
    default: break;
  }
};
