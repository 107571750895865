import { IAnimSpeed } from "../types/types";

export const TIME_LONGCLICK_DETECT = 500; // 500

export const AnimTypes = {
  UP_ON_SHORTCLICK: 1,
  UP_ON_LONGCLICK: 2,
  TIME_RETURN_ANIM: 3,

  OPP_MOVE_DURATION: 4,
  OPP_MOVE_DELAY: 5,

  START_FROM_VOID: 6,
  START_FROM_VOID_DELAY: 7,

  START_DEAL_CARD: 8,
  START_DEAL_CARD_DELAY: 9,

  START_RESORT: 10,
  PLAYER_INDICATION: 11,
}

interface ITimeItem {
  [id: number]: IAnimSpeed;
}

export const ANIM_TIMES: ITimeItem = {};
ANIM_TIMES[AnimTypes.UP_ON_SHORTCLICK] = { s: 150, m: 150, f: 150 };
ANIM_TIMES[AnimTypes.UP_ON_LONGCLICK] = { s: 150, m: 150, f: 150 };
ANIM_TIMES[AnimTypes.TIME_RETURN_ANIM] = { s: 300, m: 300, f: 300 };
ANIM_TIMES[AnimTypes.OPP_MOVE_DURATION] = { s: 500, m: 500, f: 500 };
ANIM_TIMES[AnimTypes.OPP_MOVE_DELAY] = { s: 1000, m: 500, f: 50 };
ANIM_TIMES[AnimTypes.START_FROM_VOID] = { s: 1000, m: 1000, f: 750 };
ANIM_TIMES[AnimTypes.START_FROM_VOID_DELAY] = { s: 500, m: 250, f: 5 };
ANIM_TIMES[AnimTypes.START_DEAL_CARD] = { s: 500, m: 500, f: 350 };
ANIM_TIMES[AnimTypes.START_DEAL_CARD_DELAY] = { s: 50, m: 30, f: 5 };
ANIM_TIMES[AnimTypes.START_RESORT] = { s: 200, m: 200, f: 200 };
ANIM_TIMES[AnimTypes.PLAYER_INDICATION] = { s: 500, m: 500, f: 50 };
