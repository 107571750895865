import * as csSettingsTypes from "../commonClientServer/settingsTypes";
import * as gameTypes from "../redux/modules/gameTypes";

export enum ECacheMessageType {
  CACHE_MESSAGE_DEAL,
  CACHE_MESSAGE_MOVE,
  CACHE_MESSAGE_CLEAR,
}

export enum EStopReason {
  QUIT = 0,
  ROUND_END = 1,
  GAME_SCORE = 2,
}

export interface ICacheMessageDeal {
  type: typeof ECacheMessageType.CACHE_MESSAGE_DEAL;

  gameId: string;
  difficulty: csSettingsTypes.EDifficulty;
  spades: csSettingsTypes.ESpades;
  showOppCards: boolean;
  players: gameTypes.IGamePlayer[];
  cards: string[][];
  nextPlayer: number;
}

export interface ICacheMessageMove {
  type: typeof ECacheMessageType.CACHE_MESSAGE_MOVE;
  player: number;
  taking: boolean;
  cards: string[];
  nextPlayer: number;
}

export interface ICacheMessageClear {
  type: typeof ECacheMessageType.CACHE_MESSAGE_CLEAR;
  who: number;
  why: EStopReason;
  losers?: number[];
}

export type TCacheMessage = ICacheMessageDeal | ICacheMessageMove | ICacheMessageClear;
