import { TClientMessage } from "../../commonClientServer/messagesClientTypes";

export enum EActionTypes {
  WEBSOCKET_CONNECT = "panweb/serverConnection/WEBSOCKET_CONNECT",
  WEBSOCKET_CONNECTED = "panweb/serverConnection/WEBSOCKET_CONNECTED",
  WEBSOCKET_DISCONNECT = "panweb/serverConnection/WEBSOCKET_DISCONNECT",
  WEBSOCKET_DISCONNECTED = "panweb/serverConnection/WEBSOCKET_DISCONNECTED",
  WEBSOCKET_CLIENT_ID = "panweb/serverConnection/WEBSOCKET_CLIENT_ID",
  WEBSOCKET_SEND_MESSAGE = "panweb/serverConnection/WEBSOCKET_SEND_MESSAGE",
}

export enum EWebSocketStateTypes {
  WEBSOCKET_STATE_CONNECTING = "WEBSOCKET_STATE_CONNECTING",
  WEBSOCKET_STATE_CONNECTED = "WEBSOCKET_STATE_CONNECTED",
  WEBSOCKET_STATE_DISCONNECTING = "WEBSOCKET_STATE_DISCONNECTING",
  WEBSOCKET_STATE_DISCONNECTED = "WEBSOCKET_STATE_DISCONNECTED",
}

export interface IWebSocketConnectAction {
  type: typeof EActionTypes.WEBSOCKET_CONNECT;
}
export interface IWebSocketConnectedAction {
  type: typeof EActionTypes.WEBSOCKET_CONNECTED;
}
export interface IWebSocketDisconnectAction {
  type: typeof EActionTypes.WEBSOCKET_DISCONNECT;
}
export interface IWebSocketDisconnectedAction {
  type: typeof EActionTypes.WEBSOCKET_DISCONNECTED;
}
export interface IWebSocketClientIdAction {
  type: typeof EActionTypes.WEBSOCKET_CLIENT_ID;
  clientId: string;
}

export interface IWebSocketSendMessageAction {
  type: typeof EActionTypes.WEBSOCKET_SEND_MESSAGE;
  message: TClientMessage;
}

export type TWebSocketActions = IWebSocketConnectAction | IWebSocketConnectedAction
  | IWebSocketDisconnectAction | IWebSocketDisconnectedAction
  | IWebSocketClientIdAction | IWebSocketSendMessageAction;

export interface IWebSocketState {
  socketState: EWebSocketStateTypes;
  clientId: string;
}
