import { AnyAction } from "redux";
import { EDifficulty, EKind, ESpades } from "../../commonClientServer/settingsTypes";

export enum EActionTypes {
  SETTINGS_SET_ALL = "panweb/settings/SETTINGS_SET_ALL",
}

export enum ESortCards {
  NONE,
  ASCENDING,
  DESCENDING,
}

export enum ESpeed {
  SLOW,
  MODERATE,
  FAST,
}

export interface ISettingsState {
  difficulty: EDifficulty; // constants.SETTINGS_DIFFICULTY_EASY, // 0-easy, 1-hard
  spades: ESpades; // constants.SETTINGS_SPADES_OFF, // 0-off, 1-2up, 2-3up
  name: string;
  player1_kind: EKind; // constants.SETTINGS_PLAYER_KIND_COMPUTER }
  player2_kind: EKind; // constants.SETTINGS_PLAYER_KIND_COMPUTER }
  player3_kind: EKind; // constants.SETTINGS_PLAYER_KIND_COMPUTER }
  showOppCards: boolean;
  sorting: ESortCards;
  speed: ESpeed;
  notifications: boolean;
}

export interface ISetSettingsAction {
  type: typeof EActionTypes.SETTINGS_SET_ALL;
  settings: ISettingsState;
}

export type TSettingsActions = ISetSettingsAction | AnyAction;
