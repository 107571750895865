import { FormControl, Input, InputLabel, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
// import TextField from "@material-ui/core/TextField";
import AccountCircle from "@material-ui/icons/AccountCircle";
import React from "react";
import "typeface-roboto";
import { EKind } from "../../commonClientServer/settingsTypes";
import { IComponentState, TComponentProps } from "./PlayerInfoTypes";

class PlayerInfo extends React.Component<TComponentProps, IComponentState> {
  constructor(props: TComponentProps) {
    super(props);

    this.state = {
      dataKey: props.dataKey,
      name: props.name,
      kind: props.kind,
    };
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleButtonChange = this.handleButtonChange.bind(this);
  }

  public componentDidUpdate(prevProps: TComponentProps, prevState: IComponentState, snapshot: any) {
    if (prevProps.dataKey !== this.props.dataKey
      || prevProps.name !== this.props.name
      || prevProps.kind !== this.props.kind) {
      this.setState({
        dataKey: this.props.dataKey,
        name: this.props.name,
        kind: this.props.kind,
      });
    }
  }

  public handleTextChange(event: any) {
    const { name, value } = event.target; // react syntetic event

    this.setState((state) => ({
      ...state,
      [name]: value,
    }), () => {
      this.props.onChange(this.state);
    });
  }

  public handleButtonChange(event: any) {
    let newValue: EKind;
    switch (this.state.kind) {
      case EKind.CPU: newValue = EKind.OFF; break;
      case EKind.HUMAN: newValue = EKind.OFF; break;
      case EKind.OFF: newValue = EKind.CPU; break;
      default: break;
    }

    this.setState((state) => ({
      ...state,
      kind: newValue,
    }), () => {
      this.props.onChange(this.state);
    });
  }

  public render() {
    if (this.props.dataKey === 0) {
      // const nameStyle = {
      //   width: "100%",
      //   color: "blue",
      // };

      // return <TextField
      //   id="input-with-icon-textfield"
      //   label="Your name"
      //   name="name"
      //   style={nameStyle}
      //   onChange={this.handleTextChange}
      //   value={this.state.name}
      //   InputProps={{
      //     startAdornment: (
      //       <InputAdornment position="start" >
      //         <AccountCircle style={{ color: "red" }} />
      //       </ InputAdornment>
      //     ),
      //   }}
      // />;

      return <FormControl style={{ display: "flex" }}>
        <InputLabel htmlFor="input-with-icon-adornment">Your name</InputLabel>
        <Input
          id="input-with-icon-adornment"
          name="name"
          value={this.state.name}
          onChange={this.handleTextChange}
          startAdornment={
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          }
        />
      </FormControl>;
    } else {
      const playerName = this.props.name;

      let kindSymbol;
      switch (this.state.kind) {
        case EKind.CPU: kindSymbol = "CPU"; break;
        case EKind.HUMAN: kindSymbol = "Human"; break;
        case EKind.OFF: kindSymbol = "off"; break;
        default: break;
      }

      const buttonStyle = {
        width: "100%",
        padding: "0px",
        margin: "2px",
      };

      return (
        <form style={{ display: "flex" }}>
          <Typography variant="overline" display="block" gutterBottom style={{ width: "70px" }}>
            {playerName}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleButtonChange}
            style={buttonStyle}>
            {kindSymbol}
          </Button>
        </form >
      );
    }
  }
}

export default PlayerInfo;
