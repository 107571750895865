import CssBaseline from "@material-ui/core/CssBaseline";
// import fetch from './__mocks__/fetch';
// global.fetch = fetch;
import { MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { isMobile } from "react-device-detect";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { appIsMobileAction } from "./redux/modules/app";
import configureStore from "./redux/store";
import { IStore } from "./redux/storeTypes";
import Router from "./router/router";
import * as serviceWorker from "./serviceWorker";
import "./styles/browser.scss";
import "./styles/index.scss";
import "./styles/mobile.scss";
import theme from "./styles/theme";

const store: IStore = configureStore();
store.dispatch(appIsMobileAction(isMobile));

console.log("redirect_uri=" + window.location.origin);

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
