import { AppBar, createStyles, IconButton, makeStyles, Theme, Toolbar, Typography } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import * as customTheme from "../../styles/theme";
import { TComponentProps } from "./HeaderTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      ...customTheme.appBar,
    },
    menuButton: {
      // marginRight: theme.spacing(2),
      color: "inherit",
      edge: "start",
      ariaLabel: "menu",
    },
    title: {
      width: "100%",
    },
    hello: {
      width: "100%",
      margin: "5px 15px 5px 15px",
    },
    loginButton: {
      color: "inherit",
    },
    logoutButton: {
      color: "inherit",
    },
  }),
);

function Header(props: TComponentProps) {
  const classes = useStyles();

  const menuButtonOnClick = (e: any) => {
    if (!props.mobileSlide) {
      props.setMobileSlideOn();
    }
  };

  let menuButton;
  if (props.isMobile) {
    menuButton = <IconButton className={classes.menuButton} onClick={menuButtonOnClick}>
      <MenuIcon />
    </IconButton>;
  }

  return <AppBar className={classes.appBar} >
    <Toolbar style={{ display: "flex", flexDirection: "row", flex: "1", padding: "0px", margin: "0px" }}>
      <div style={{ display: "flex", flex: "1" }}>
        {menuButton}
      </div>
      <div style={{ display: "flex", width: "50px" }}>
        <Typography className={classes.title}>PAN</Typography>
      </div>
      <div style={{ display: "flex", flex: "1", justifyContent: "flex-end", margin: "0 10px 0 0" }}>
      </div>
    </Toolbar>
  </AppBar>;
}

export default Header;
