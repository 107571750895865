import { System } from "ecsy";
import GameCommandComponent from "../components/gameCommandComponent";
import ManagersComponent from "../components/managersComponent";
import { gameStateSystemExecute } from "./gameStateSystemExecute";

export default class GameStateSystem extends System {

  static queries = {
    commands: {
      components: [GameCommandComponent],
    },
    common: {
      components: [ManagersComponent],
      mandatory: true,
    },
  };

  execute(delta, time) {
    // console.log(`common comp len: ${this.queries.common.results.length}`);
    // console.log(this.queries.common.results.map((e)=>{
    //   return {
    //     managerComponent: e.getComponent(ManagersComponent),
    //   };
    // }));

    gameStateSystemExecute(delta, time,
      this.queries.common.results[0].getComponent(ManagersComponent),
      this.queries.commands.results);
  }
}
