import { World } from "ecsy";
import PlayerEastComponent from "../components/playerEastComponent";
import PlayerNorthComponent from "../components/playerNorthComponent";
import PlayerSouthComponent from "../components/playerSouthComponent";
import PlayerWestComponent from "../components/playerWestComponent";
import { ICardPosition, ICoords, IPoint } from "../types/types";
import { queryComponents } from "./ecsy";

export const getPosition = (angle: number, radius: number, tableCoords: ICoords): ICardPosition => {
  const point = getCircumferencePoint(angle, radius);

  return {
    x: tableCoords.centreX + point.x,
    y: tableCoords.centreY + point.y,
    angle,
    zIndex: 1,
  };
};

export const getAngle = (tableCoords: ICoords, playerCoords: ICoords): number => {
  const x1 = tableCoords.centreX;
  const y1 = tableCoords.centreY;
  const x2 = playerCoords.centreX;
  const y2 = playerCoords.centreY;

  const angle = Math.atan2(y2 - y1, x2 - x1);
  // console.log(`angle - radians: ${angle}, degrees: ${180 * angle / Math.PI}`);
  return angle;
};

const getCircumferencePoint = (angle: number, radius: number): IPoint => {
  return {
    x: radius * Math.cos(angle),
    y: radius * Math.sin(angle),
  };
};

export const isNextPlayerForward = (playerFrom: number, playerTo: number, world: World): boolean => {
  if (playerFrom === playerTo) { return false; }

  const players: number[] = [];
  players.push(queryComponents(world, [PlayerSouthComponent]).length);
  players.push(queryComponents(world, [PlayerWestComponent]).length);
  players.push(queryComponents(world, [PlayerNorthComponent]).length);
  players.push(queryComponents(world, [PlayerEastComponent]).length);

  const activePlayersCount = players.filter((count) => count > 0).length;

  if (activePlayersCount >= 2) {
    for (let i = 1; i <= 2; i++) {
      const playerIndex = (playerFrom + i) % 4;
      if (playerIndex === playerTo) { break; }
      if (playerIndex !== playerTo && players[playerIndex] > 0) {
        return false;
      }
    }
  }

  return true;
};
