import { System } from "ecsy";
import InteractiveComponent from "../components/interactiveComponent";
import ClickedComponent from "../components/clickedComponent";
import PositionOriginComponent from "../components/positionOriginComponent";
import ManagersComponent from "../components/managersComponent";
import CardComponent from "../components/cardComponent";
import DraggedComponent from "../components/draggedComponent";
import TableComponent from "../components/tableComponent";
import TableOddsComponent from "../components/tableOddsComponent";
import PlayerSouthComponent from "../components/playerSouthComponent";
import { inputControlSystemExecute } from "./inputControlSystemExecute";

export default class InputControlSystem extends System {
  static queries = {
    cards: { components: [CardComponent] },
    interactive: { components: [InteractiveComponent] },
    clicked: { components: [ClickedComponent] },
    dragged: { components: [DraggedComponent] },
    positionOrigin: { components: [PositionOriginComponent] },
    table: { components: [TableComponent, TableOddsComponent] },
    playerSouth: { components: [PlayerSouthComponent] },
    common: {
      components: [ManagersComponent],
      mandatory: true,
    },
  };

  execute(delta, time) {
    inputControlSystemExecute(delta, time,
      this.queries.common.results[0].getComponent(ManagersComponent),
      this.queries.cards.results,
      this.queries.interactive.results,
      this.queries.clicked.results,
      this.queries.dragged.results,
      this.queries.positionOrigin.results,
      this.queries.table.results,
      this.queries.playerSouth.results);
  }
}
