// import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ICacheMessageClear, ICacheMessageDeal, ICacheMessageMove } from "../../common/messagesCacheTypes";
import { appFinishDialogAction } from "../../redux/modules/app";
import { gameNextPlayerAction, gameServerMoveAction, gameServerStartAction, gameServerStopAction, gameUserMoveAction } from "../../redux/modules/game";
import { EGameStage } from "../../redux/modules/gameTypes";
import { getEffectiveSettings } from "../../redux/modules/settings";
import { IStoreState } from "../../redux/storeTypes";
import { Board } from "./Board";
import { IBoardGameSettings, IMapDispatchToProps, IMapStateToProps, IOwnProps } from "./BoardTypes";

const mapStateToProps = (state: IStoreState, ownProps: IOwnProps): IMapStateToProps => ({
  ...ownProps,
  isMobile: state.app.isMobile,
  gameStage: state.game.stage,
  gameSettings: getGameSettings(state),
  finishDialog: state.app.finishDialog,
  finishDialogTitle: state.app.finishDialogTitle,
  finishDialogInfo1: state.app.finishDialogInfo1,
  finishDialogInfo2: state.app.finishDialogInfo2,
});

const mapDispatchToProps = (dispatch: Dispatch): IMapDispatchToProps => ({
  serverGameStart: (message: ICacheMessageDeal) =>
    dispatch(gameServerStartAction(
      message.gameId,
      message.nextPlayer,
      {
        difficulty: message.difficulty,
        spades: message.spades,
        showOppCards: message.showOppCards,
        players: message.players,
      })),
  serverGameStop: (message: ICacheMessageClear) => dispatch(gameServerStopAction(message.who, message.why, message.losers)),
  serverGameMove: (message: ICacheMessageMove) => dispatch(gameServerMoveAction(message.player, message.taking, message.cards, message.nextPlayer)),
  userGameMove: (taking: boolean, cardSymbols: string[]) => dispatch(gameUserMoveAction(taking, cardSymbols)),
  userGameMoveNextPlayer: (nextPlayer: number) => dispatch(gameNextPlayerAction(nextPlayer)),
  setFinishDialogOff: () => dispatch(appFinishDialogAction(false, "", "", "")),
});

const getGameSettings = (state: IStoreState): IBoardGameSettings => {
  const curSettings = getEffectiveSettings(
    state.settings,
    state.game.settings,
    state.game.stage !== EGameStage.STOPPED);

  return {
    sorting: curSettings.sorting,
    speed: curSettings.speed,
    showOppCards: curSettings.showOppCards,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Board);
