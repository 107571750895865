import { Entity } from "ecsy";
import CardComponent from "../components/cardComponent";
import PositionComponent from "../components/positionComponent";

export interface ISortedEntityItem {
  entity: Entity;
  positionComponent: PositionComponent;
}

export const sortEntities = (enitities: Entity[]): ISortedEntityItem[] => {
  return enitities
    .map((entity) => ({
      entity,
      positionComponent: entity.getComponent(PositionComponent),
    }))
    .sort((i1, i2) => {
      if (i1.positionComponent.zIndex > i2.positionComponent.zIndex) { return 1; }
      if (i1.positionComponent.zIndex < i2.positionComponent.zIndex) { return -1; }
      return 0;
    });
};

export interface ISortedEntityItem2 {
  entity: Entity;
  positionComponent: PositionComponent;
}

export const getPositionsSortedByCard = (entities: Entity[], ascending: boolean): number[] => {
  const mapped = entities
    .map((entity, index) => ({
      entity,
      orgIndex: index,
      newIndex: -1,
      cardComponent: entity.getComponent(CardComponent),
    }));

  if (ascending) {
    mapped.sort((i1, i2) => {
      if (i1.cardComponent.rank > i2.cardComponent.rank) { return 1; }
      if (i1.cardComponent.rank < i2.cardComponent.rank) { return -1; }

      if (i1.cardComponent.suit > i2.cardComponent.suit) { return 1; }
      if (i1.cardComponent.suit < i2.cardComponent.suit) { return -1; }

      return 0;
    });
  } else {
    mapped.sort((i1, i2) => {
      if (i1.cardComponent.rank > i2.cardComponent.rank) { return -1; }
      if (i1.cardComponent.rank < i2.cardComponent.rank) { return 1; }

      if (i1.cardComponent.suit > i2.cardComponent.suit) { return 1; }
      if (i1.cardComponent.suit < i2.cardComponent.suit) { return -1; }

      return 0;
    });
  }

  mapped.forEach((item, index) => { item.newIndex = index; });

  return mapped
    .sort((i1, i2) => {
      if (i1.orgIndex > i2.orgIndex) { return 1; }
      if (i1.orgIndex < i2.orgIndex) { return -1; }
      return 1;
    })
    .map((item) => item.newIndex);
};
