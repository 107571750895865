import { Entity } from "ecsy";
import { ESortCards } from "../../redux/modules/settingsTypes";
import { handleButtonClick } from "../common/buttonsHandler";
import { AnimTypes } from "../common/constants";
import { grabFirstCardFromPlayer, grabRestCardsFromPlayer } from "../common/grabFromPlayer";
import { grabCardsFromTable } from "../common/grabFromTable";
import { applyTweenForPositionWithCancel } from "../common/tweenHelper";
import { getPositionsSortedByCard, sortEntities } from "../common/utils";
import ButtonComponent from "../components/buttonComponent";
import CardComponent from "../components/cardComponent";
import ClickedComponent from "../components/clickedComponent";
import DraggedComponent from "../components/draggedComponent";
import InteractiveComponent from "../components/interactiveComponent";
import ManagersComponent from "../components/managersComponent";
import PlayerEastComponent from "../components/playerEastComponent";
import PlayerNorthComponent from "../components/playerNorthComponent";
import PlayerSouthComponent from "../components/playerSouthComponent";
import PlayerWestComponent from "../components/playerWestComponent";
import PositionComponent from "../components/positionComponent";
import PositionOriginComponent from "../components/positionOriginComponent";
import SnappedToTargetComponent from "../components/snappedToTargetComponent";
import TableComponent from "../components/tableComponent";
import TableOddsComponent from "../components/tableOddsComponent";
import { ICardPosition } from "../types/types";

export const inputControlSystemExecute = (
  delta: number, time: number,
  common: ManagersComponent,
  qrCards: Entity[],
  qrInteractive: Entity[],
  qrClicked: Entity[],
  qrDragged: Entity[],
  qrPositionOrigin: Entity[],
  qrTable: Entity[],
  qrPlayerSouth: Entity[]) => {

  if (common.collocationManager.newScreenSize) {
    common.collocationManager.newScreenSize = false;
    onScreenResize(common, qrClicked, qrDragged, qrCards, qrPositionOrigin, qrTable, qrPlayerSouth, qrInteractive);
  }

  if (common.inputControlManager.systemUpdate(time)) {
    const input = common.inputControlManager.getInput();
    common.inputControlManager.resetChanged();

    // console.log(input);
    if (input.pressed) {
      if (input.pressedChanged) {
        // todo: ..prevent selecting cards in motion...
        if (input.longPressed) {
          onLongPressed(common, qrPlayerSouth, qrClicked);
        } else {
          onShortPressed(common, qrClicked, qrInteractive, qrPlayerSouth, qrTable, input.x, input.y);
        }
      }
    } else {
      if (input.pressedChanged) {
        onUnpressed(common, qrClicked, qrDragged, qrPositionOrigin, qrTable, qrPlayerSouth, qrInteractive);
      }
    }
  }
};

const onScreenResize = (
  common: ManagersComponent,
  qrClicked: Entity[],
  qrDragged: Entity[],
  qrCards: Entity[],
  qrPositionOrigin: Entity[],
  qrTable: Entity[],
  qrPlayerSouth: Entity[],
  qrInteractive: Entity[]) => {

  const TIME_RETURN_ANIM = common.gameStateManager.getAnimTime(AnimTypes.TIME_RETURN_ANIM);
  onUnpressed(common, qrClicked, qrDragged, qrPositionOrigin, qrTable, qrPlayerSouth, qrInteractive);

  const sets: [any[], any[], any[], any[], any[]] = [[], [], [], [], []];

  qrCards.forEach((entity) => {
    let index = -1;
    if (entity.hasComponent(TableComponent)) { index = 0; }
    // tslint:disable-next-line: one-line
    else if (entity.hasComponent(PlayerSouthComponent)) { index = 1; }
    // tslint:disable-next-line: one-line
    else if (entity.hasComponent(PlayerWestComponent)) { index = 2; }
    // tslint:disable-next-line: one-line
    else if (entity.hasComponent(PlayerNorthComponent)) { index = 3; }
    // tslint:disable-next-line: one-line
    else if (entity.hasComponent(PlayerEastComponent)) { index = 4; }

    if (index >= 0) {
      sets[index].push(entity);
    }
  });

  for (let p = 0; p < sets.length; p++) {
    const sortedCards = sortEntities(sets[p]);

    let positions: ICardPosition[];

    if (p === 0) {
      const tableOddsComponents = sortedCards.map((item) => item.entity.getComponent(TableOddsComponent));
      const tablePositions = common.collocationManager.getTablePositionsAsRandomPile(tableOddsComponents.length, tableOddsComponents);
      positions = tablePositions.positions;
    } else {
      positions = common.collocationManager.getPlayerPositionsAsRow(p, sortedCards.length);
    }

    sortedCards.forEach((item, index) => {
      applyTweenForPositionWithCancel(item.entity, positions[index], TIME_RETURN_ANIM);
    });
  }
};

const onShortPressed = (common: ManagersComponent, qrClicked: Entity[], qrInteractive: Entity[], qrPlayer: Entity[], qrTable: Entity[], x: number, y: number) => {
  const ctxHit = common.collocationManager.ctxHit;

  // nothing dragged yet, so no clicked either
  const entitiesClicked = qrClicked;
  if (entitiesClicked.length > 0) {
    console.warn("ClickedComponent here? impossible!");
    return;
  }

  const pixel = ctxHit.getImageData(x, y, 1, 1).data;
  const clickedColor = pixel[0];
  if (clickedColor > 0) {
    const interactiveEntity = qrInteractive.find((entity) => entity.getComponent(InteractiveComponent).colorIndex === clickedColor);
    if (!interactiveEntity) { return; }

    if (interactiveEntity.hasComponent(PlayerSouthComponent)) {
      grabFirstCardFromPlayer(common.collocationManager, common.gameStateManager, interactiveEntity);

    } else if (interactiveEntity.hasComponent(TableComponent)) {
      grabCardsFromTable(common.collocationManager, common.gameStateManager, interactiveEntity, qrTable, qrPlayer.length);

    } else if (interactiveEntity.hasComponent(ButtonComponent)) {
      handleButtonClick(
        common.collocationManager,
        common.gameStateManager,
        interactiveEntity,
        qrTable,
        qrPlayer);
    }
  }
};

const onLongPressed = (common: ManagersComponent, qrPlayerSouth: Entity[], qrClicked: Entity[]) => {
  if (qrClicked.length === 1) {
    if (qrClicked[0].hasComponent(PlayerSouthComponent)) {
      grabRestCardsFromPlayer(common.collocationManager, common.gameStateManager, qrClicked[0], qrPlayerSouth);
      return;
    }
  }
};

const onUnpressed = (
  common: ManagersComponent,
  qrClicked: Entity[],
  qrDragged: Entity[],
  qrPositionOrigin: Entity[],
  qrTable: Entity[],
  qrPlayerSouth: Entity[],
  qrInteractive: Entity[]) => {

  const TIME_LONGCLICK_ANIM = common.gameStateManager.getAnimTime(AnimTypes.UP_ON_LONGCLICK);

  let draggedToPlayer = false;
  let draggedToTable = false;

  const cardEntities = qrDragged.map((entity) => entity);

  if (qrClicked.length > 0 &&
    qrPlayerSouth.length > 0 &&
    qrClicked[0].hasComponent(TableComponent) &&
    qrClicked[0].hasComponent(DraggedComponent)) {

    const cardSize = common.collocationManager.getCardSize(1);
    const draggedY = qrClicked[0].getComponent(PositionComponent).y;
    if (draggedY >= (qrPlayerSouth[0].getComponent(PositionComponent).y - cardSize.h)) {
      draggedToPlayer = true;
    }
  }

  qrPositionOrigin.map((entity) => entity).forEach((entity) => {
    if (draggedToPlayer) {
      entity.removeComponent(TableComponent);
      entity.addComponent(PlayerSouthComponent);
      entity.removeComponent(PositionOriginComponent);

    } else if (entity.hasComponent(SnappedToTargetComponent) && entity.hasComponent(PlayerSouthComponent)) {
      entity.removeComponent(PlayerSouthComponent);
      entity.addComponent(TableComponent);
      entity.removeComponent(PositionOriginComponent);
      draggedToTable = true;
    }

    if (entity.hasComponent(PositionOriginComponent)) {
      const positionOriginComponent = entity.getComponent(PositionOriginComponent);
      applyTweenForPositionWithCancel(entity, positionOriginComponent, TIME_LONGCLICK_ANIM);
      entity.removeComponent(PositionOriginComponent);
    }

    entity.removeComponent(DraggedComponent);
    entity.removeComponent(ClickedComponent);
    entity.removeComponent(SnappedToTargetComponent);

    if (entity.hasComponent(PlayerSouthComponent)) {
      entity.removeComponent(TableOddsComponent);
    }
  });

  if (draggedToPlayer || draggedToTable) {
    const newPlayerPositions = common.collocationManager.getPlayerPositionsAsRow(1, qrPlayerSouth.length);
    sortEntities(qrPlayerSouth)
      .forEach((item, itemIndex) => {
        applyTweenForPositionWithCancel(item.entity, newPlayerPositions[itemIndex], TIME_LONGCLICK_ANIM);
      });

    const sortedTable = sortEntities(qrTable);
    const newTablePositions = common.collocationManager.getTablePositionsAsRandomPile(
      qrTable.length,
      sortedTable.map((item) => item.entity.getComponent(TableOddsComponent)));

    let firstInteractiveIndex = qrTable.length - 3;
    if (firstInteractiveIndex < 1) { firstInteractiveIndex = 1; }

    sortedTable
      .forEach((item, itemIndex) => {
        const positionComponent = item.entity.getMutableComponent(PositionComponent);
        positionComponent.copyFromAny(newTablePositions.positions[itemIndex]);
        if (itemIndex >= firstInteractiveIndex) {
          item.entity.addComponent(InteractiveComponent, { colorIndex: -1 });
        } else {
          item.entity.removeComponent(InteractiveComponent);
        }
      });

    let index = 0;
    qrInteractive.forEach((entity) => {
      if (entity.hasComponent(CardComponent)) {
        index++;
        entity.getMutableComponent(InteractiveComponent).colorIndex = index;
      }
    });

    if (draggedToPlayer && common.gameStateManager.gameSettings.sorting !== ESortCards.NONE) {
      const sortedIndexes = getPositionsSortedByCard(qrPlayerSouth, common.gameStateManager.gameSettings.sorting === ESortCards.ASCENDING);
      qrPlayerSouth.forEach((entity, entityIndex) => {
        applyTweenForPositionWithCancel(entity, newPlayerPositions[sortedIndexes[entityIndex]], TIME_LONGCLICK_ANIM);
      });
    }

    if (draggedToPlayer) {
      common.gameStateManager.emitGameMoveTake(sortEntities(cardEntities).map((item) => item.entity.getComponent(CardComponent).symbol));
    } else {
      common.gameStateManager.emitGameMovePut(common, sortEntities(cardEntities).map((item) => item.entity.getComponent(CardComponent).symbol));
    }
  }

  qrClicked.forEach((entity) => entity.removeComponent(ClickedComponent));
};
