import { System } from "ecsy";
import CardComponent from "../components/cardComponent";
import PositionComponent from "../components/positionComponent";
import ManagersComponent from "../components/managersComponent";
import PlayerSouthComponent from "../components/playerSouthComponent";
import PlayerWestComponent from "../components/playerWestComponent";
import PlayerNorthComponent from "../components/playerNorthComponent";
import PlayerEastComponent from "../components/playerEastComponent";
import TableComponent from "../components/tableComponent";
import { rendererSystemExecute } from "./rendererSystemExecute";

export default class RendererSystem extends System {

  static queries = {
    renderable: {
      components: [PositionComponent],
    },
    cards: {
      components: [CardComponent],
    },
    table: {
      components: [TableComponent],
    },
    playerSouth: {
      components: [PlayerSouthComponent],
    },
    playerWest: {
      components: [PlayerWestComponent],
    },
    playerNorth: {
      components: [PlayerNorthComponent],
    },
    playerEast: {
      components: [PlayerEastComponent],
    },
    common: {
      components: [ManagersComponent],
      mandatory: true,
    },
  };

  execute() {
    rendererSystemExecute(
      this.queries.common.results[0].getComponent(ManagersComponent),
      this.queries.renderable.results);
  }
}
