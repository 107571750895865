import React from "react";
import "../../styles/error.css";

export default class Error extends React.Component {
  public render() {
    return <div className="container">
      <div className="center">
        <h1 className="text">error, the page does not exist!</h1>
      </div>
    </div>;
  }
}
