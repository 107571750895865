import { connect } from "react-redux";
import { Dispatch } from "redux";
import { appMobileSlideAction } from "../../redux/modules/app";
import { IStoreState } from "../../redux/storeTypes";
import Header from "./Header";
import { IMapDispatchToProps, IMapStateToProps, IOwnProps } from "./HeaderTypes";

const mapStateToProps = (state: IStoreState, ownProps: IOwnProps): IMapStateToProps => ({
  ...ownProps,
  isMobile: state.app.isMobile,
  mobileSlide: state.app.mobileSlide,
});

const mapDispatchToProps = (dispatch: Dispatch): IMapDispatchToProps => ({
  setMobileSlideOn: () => dispatch(appMobileSlideAction(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
