import { connect } from "react-redux";
import { EKind } from "../../commonClientServer/settingsTypes";
import { appMobileSlideAction } from "../../redux/modules/app";
import { gameUserStartAction, gameUserStopAction } from "../../redux/modules/game";
import { EGameStage } from "../../redux/modules/gameTypes";
import { EWebSocketStateTypes } from "../../redux/modules/webSocketTypes";
import { IStoreState } from "../../redux/storeTypes";
import Status from "./Status";
import { IMapDispatchToProps, IMapStateToProps, IOwnProps } from "./StatusTypes";
import { Dispatch } from "redux";

const mapStateToProps = (state: IStoreState, ownProps: IOwnProps): IMapStateToProps => ({
  ...ownProps,
  gameStage: state.game.stage,
  serverConnectionStatus: state.webSocket.socketState,
  mobileSlide: state.app.mobileSlide,
  startEnabled: state.game.stage === EGameStage.AWAITING ||
    (state.webSocket.socketState === EWebSocketStateTypes.WEBSOCKET_STATE_CONNECTED && state.settings.name.length > 0 &&
      (state.settings.player1_kind !== EKind.OFF || state.settings.player2_kind !== EKind.OFF || state.settings.player3_kind !== EKind.OFF)),
});

const mapDispatchToProps = (dispatch: Dispatch): IMapDispatchToProps => ({
  gameStart: () => dispatch(gameUserStartAction()),
  gameQuit: () => dispatch(gameUserStopAction()),
  setMobileSlideOff: () => dispatch(appMobileSlideAction(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Status);
