import { System } from "ecsy";
import ClickedComponent from "../components/clickedComponent";
import DraggedComponent from "../components/draggedComponent";
import ManagersComponent from "../components/managersComponent";
import PlayerSouthComponent from "../components/playerSouthComponent";
import TableComponent from "../components/tableComponent";
import { draggedSystemExecute } from "./draggedSystemExecute";

export default class DraggedSystem extends System {
  static queries = {
    dragged: { components: [DraggedComponent] },
    clicked: { components: [ClickedComponent] },
    player: { components: [PlayerSouthComponent] },
    table: { components: [TableComponent] },
    tableDragged: { components: [TableComponent, DraggedComponent] },
    common: {
      components: [ManagersComponent],
      mandatory: true,
    },
  };

  execute(delta, time) {
    draggedSystemExecute(delta, time,
      this.queries.common.results[0].getComponent(ManagersComponent),
      this.queries.dragged.results,
      this.queries.clicked.results,
      this.queries.player.results,
      this.queries.table.results,
      this.queries.tableDragged.results);
  }
}
