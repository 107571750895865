import { connect } from "react-redux";
import { Dispatch } from "redux";
import { EGameStage } from "../../redux/modules/gameTypes";
import { setSettings } from "../../redux/modules/settings";
import { ISettingsState } from "../../redux/modules/settingsTypes";
import { IStoreState } from "../../redux/storeTypes";
import SettingsView from "./Settings";
import { IMapStateToProps } from "./SettingsTypes";

const mapStateToProps = (state: IStoreState): IMapStateToProps => ({
  settings: state.settings,
  gameSettings: state.game.settings,
  isLocked: state.game.stage !== EGameStage.STOPPED
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSetSettings: (settings: ISettingsState) => dispatch(setSettings(settings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsView);
