export const PAGE_ALL_SIZE = 1000;
export const PAGE_BOARD_SIZE = 800;
export const PAGE_SIDES_SIZE = 200;

export const CARD_WIDTH = 95; // 48;
export const CARD_HEIGHT = 133; // 70;
export const CARDS_VIEW_MAX_WIDTH = 480; // 320
export const CARD_POSITION_TOP = 12; // 24;
export const TABLE_AREA_SIZE = 200;

export const CANVAS_SCALE_FACTOR_MOBILE = 1.5;

export const PLAYER_NAME_DEFAULT = "South";
export const PLAYER_NAME_WEST = "West";
export const PLAYER_NAME_NORTH = "North";
export const PLAYER_NAME_EAST = "East";

export const IDLE_TIME_DETECTION = 2 * 60 * 1000; // miliseconds
export const IDLE_TIME_DIALOG = 60; // seconds
export const MESSAGE_HANDLE_DELAY = 400;

export const NOTIFICATIONS_MAX_COUNT = 5;
export const NOTIFICATIONS_INFO_DURATION = 5;
export const NOTIFICATIONS_ERROR_DURATION = 10;

export const FULL_RANK_SELECTION_DELAY = 500;
export const GRAB_CARDS_SHIFT = 0.5;

export const URL_NOKADASOFT = "https://www.nokadasoft.com";
export const IMG_NOKADASOFT = "logo_nokadasoft_light_33.png";

export const URL_ARCHITECTUREDESIGN = "https://nokadasoft.com/pan-website";
export const IMG_ARCHITECTUREDESIGN = "architecturebanner.png";

export interface IRect {
  left: number;
  right: number;
  top: number;
  bottom: number;
}
