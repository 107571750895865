import { System } from "ecsy";
import TweenComponent from "../components/tweenComponent";
import { tweenSystemExecute } from "./tweenSystemExecute";

export default class TweenSystem extends System {

  static queries = {
    tweens: {
      components: [TweenComponent],
    },
  };

  execute(delta, time) {
    tweenSystemExecute(time, this.queries.tweens.results);
  }
}
