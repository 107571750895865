import { createMuiTheme } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

// "rgb(34, 28, 10)"
// const baseColor = "rgb(47, 79, 79)";
const headerColor = "rgb(47, 79, 79)";
const mainColor = "rgb(107, 139, 139)";

export const appBar: CSSProperties = {
  position: "static",
  color: "rgb(233, 220, 41)",
  textAlign: "center",
  backgroundColor: headerColor,
};

// https://material-ui.com/customization/default-theme/
const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      // light: "rgb(255, 28, 10)",
      // main: "rgb(54, 48, 30)",
      main: mainColor, // "rgb(47, 79, 79)",
      // dark: "rgb(34, 28, 10)",
      // contrastText: "rgb(233, 220, 41)",
    },
    secondary: {
      // light: "rgb(255, 28, 10)",
      main: mainColor,
      // dark: "rgb(34, 28, 10)",
      // contrastText: "#00bfa5",
    },
    background: {
      // paper: "rgb(47, 79, 79)",
      // default: "rgb(47, 79, 79)",
    },
  },
});

export default theme;
