export enum EDifficulty {
  EASY = 0,
  HARD = 1,
}

export enum ESpades {
  OFF = 0,
  P2UP = 1, // two players or more
  P3UP = 2, // three players only
}

export enum EKind {
  OFF = 0,
  CPU = 1,
  HUMAN = 2,
}
