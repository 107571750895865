import { Component, Entity, World } from "ecsy";
import { queryComponentsNative, removeAllEntitiesNative, removeComponentForcedNative } from "./ecsyNative";

export const removeComponentForced = (entity: Entity, component: Component) => {
  removeComponentForcedNative(entity, component);
};

export const registerComponent = (world: World, component: any, name: string) => {
  Object.defineProperty(component, "name", { value: name });
  world.registerComponent(component);
};

export const registerSystem = (world: World, system: any, name: string) => {
  Object.defineProperty(system, "name", { value: name });
  world.registerSystem(system);
};

export const removeAllEntities = (world: World) => {
  removeAllEntitiesNative(world);
};

export const queryComponents = (world: World, components: any[]): Entity[] => {
  const results = queryComponentsNative(world, components);
  return results;
};
