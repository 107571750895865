import { Component } from "ecsy";

export enum ETypes {
  DEFAULT,
  INDICATOR,
}

export default class TypeComponent extends Component {
  public type: ETypes = ETypes.DEFAULT;

  public reset() {
    this.type = ETypes.DEFAULT;
  }
}
