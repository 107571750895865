import { TClientMessage } from "../../commonClientServer/messagesClientTypes";
import * as types from "./webSocketTypes";

export const SERVER_CONNECTION_WEBSOCKET_INITIAL_STATE: types.IWebSocketState = {
  socketState: types.EWebSocketStateTypes.WEBSOCKET_STATE_DISCONNECTED,
  clientId: "",
};

const reducer = (state: types.IWebSocketState = SERVER_CONNECTION_WEBSOCKET_INITIAL_STATE, action: types.TWebSocketActions) => {
  switch (action.type) {
    case types.EActionTypes.WEBSOCKET_CONNECT:
      return {
        socketState: types.EWebSocketStateTypes.WEBSOCKET_STATE_CONNECTING,
        clientId: "",
      };
    case types.EActionTypes.WEBSOCKET_CONNECTED:
      return {
        ...state,
        socketState: types.EWebSocketStateTypes.WEBSOCKET_STATE_CONNECTED,
        clientId: "",
      };
    case types.EActionTypes.WEBSOCKET_DISCONNECT:
      return {
        ...state,
        socketState: types.EWebSocketStateTypes.WEBSOCKET_STATE_DISCONNECTING,
        clientId: "",
      };
    case types.EActionTypes.WEBSOCKET_DISCONNECTED:
      return {
        ...state,
        socketState: types.EWebSocketStateTypes.WEBSOCKET_STATE_DISCONNECTED,
        clientId: "",
      };
    case types.EActionTypes.WEBSOCKET_CLIENT_ID:
      return {
        ...state,
        socketState: types.EWebSocketStateTypes.WEBSOCKET_STATE_CONNECTED,
        clientId: action.clientId,
      };

    default:
      return state;
  }
};

export default reducer;

export const webSocketConnect = (): types.IWebSocketConnectAction => ({ type: types.EActionTypes.WEBSOCKET_CONNECT });
export const webSocketConnected = (): types.IWebSocketConnectedAction => ({ type: types.EActionTypes.WEBSOCKET_CONNECTED });
export const webSocketDisconnect = (): types.IWebSocketDisconnectAction => ({ type: types.EActionTypes.WEBSOCKET_DISCONNECT });
export const webSocketDisconnected = (): types.IWebSocketDisconnectedAction => ({ type: types.EActionTypes.WEBSOCKET_DISCONNECTED });
export const webSocketClientId = (clientId: string): types.IWebSocketClientIdAction => ({ type: types.EActionTypes.WEBSOCKET_CLIENT_ID, clientId });
export const webSocketSendMessage = (message: TClientMessage): types.IWebSocketSendMessageAction => ({ type: types.EActionTypes.WEBSOCKET_SEND_MESSAGE, message });
