import { TIME_LONGCLICK_DETECT } from "../common/constants";

export default class InputControlManager {
  private x: number = 0;
  private y: number = 0;
  private pressed: boolean = false;
  private longPressed: boolean = false;

  private whenPressed: number = 0;
  private notMovedMuch: boolean = false;

  private orgX: number = 0;
  private orgY: number = 0;
  private coordsChanged: boolean = false;
  private pressedChanged: boolean = false;

  private frozenInput: any;

  public setInput(x: number, y: number, pressed: boolean) {
    if (!pressed) {
      x = this.orgX;
      y = this.orgY;
    }

    if (this.x !== x || this.y !== y) {
      this.coordsChanged = true;
    }

    if (this.pressed !== pressed) {
      this.pressedChanged = true;
    }

    this.x = x;
    this.y = y;
    if (this.pressed === false && pressed === true) {
      this.whenPressed = performance.now();
      this.orgX = x;
      this.orgY = y;
      this.notMovedMuch = true;
    } else if (this.pressed === true && pressed === true) {
      if (this.notMovedMuch) {
        const delta = this.getDelta();
        if (Math.hypot(delta.dx, delta.dy) > 20) {
          this.notMovedMuch = false;
        }
      }
    } else {
      this.notMovedMuch = false;
      this.longPressed = false;
    }
    this.pressed = pressed;
  }

  public systemUpdate(time: number): boolean {
    if (this.notMovedMuch && (time - this.whenPressed) > TIME_LONGCLICK_DETECT) {
      this.longPressed = true;
      this.notMovedMuch = false;
      this.pressedChanged = true;
    }

    this.freezeInput();

    return this.coordsChanged || this.pressedChanged;
  }

  public getDelta() {
    return {
      dx: this.frozenInput.x - this.frozenInput.orgX,
      dy: this.frozenInput.y - this.frozenInput.orgY,
    };
  }

  public getInput = () => this.frozenInput;

  public resetChanged() {
    this.coordsChanged = false;
    this.pressedChanged = false;
    this.longPressed = false;
  }

  private freezeInput() {
    this.frozenInput = {
      coordsChanged: this.coordsChanged,
      pressedChanged: this.pressedChanged,
      pressed: this.pressed,
      longPressed: this.longPressed,
      moved: this.coordsChanged,
      x: this.x,
      y: this.y,
      orgX: this.orgX,
      orgY: this.orgY,
    };
  }
}
