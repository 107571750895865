import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as notificationsActions from "../../redux/modules/notifications";
import { IStoreState } from "../../redux/storeTypes";
import { Notifications } from "./Notifications";
import { IMapDispatchToProps, IMapStateToProps } from "./NotificationsTypes";

const mapStateToProps = (state: IStoreState): IMapStateToProps => ({
  notifications: state.notifications,
});

const mapDispatchToProps = (dispatch: Dispatch): IMapDispatchToProps => ({
  hideNotification: (uid: number) => dispatch(notificationsActions.hide(uid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
