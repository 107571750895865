import { Reducer } from "redux";
import * as constants from "../../common/constants";
import * as settingTypes from "../../commonClientServer/settingsTypes";
import { IGameSettings } from "./gameTypes";
import { EActionTypes, ESortCards, ESpeed, ISettingsState, TSettingsActions } from "./settingsTypes";

export const SETTINGS_INITIAL_STATE: ISettingsState = {
  difficulty: settingTypes.EDifficulty.EASY,
  spades: settingTypes.ESpades.OFF,
  name: constants.PLAYER_NAME_DEFAULT,
  player1_kind: settingTypes.EKind.CPU,
  player2_kind: settingTypes.EKind.CPU,
  player3_kind: settingTypes.EKind.CPU,
  showOppCards: false,
  sorting: ESortCards.NONE,
  speed: ESpeed.MODERATE,
  notifications: false,
};

const reducer: Reducer<ISettingsState, TSettingsActions> = (state = SETTINGS_INITIAL_STATE, action): ISettingsState => {
  switch (action.type) {
    case EActionTypes.SETTINGS_SET_ALL:
      return { ...action.settings };
    default:
      return state;
  }
};

export default reducer;

export const setSettings = (settings: ISettingsState): TSettingsActions => ({
  type: EActionTypes.SETTINGS_SET_ALL,
  settings,
});

export const getEffectiveSettings = (
  curSettings: ISettingsState,
  gameSettings: IGameSettings | undefined,
  isLocked: boolean)
  : ISettingsState => {

  const settings = { ...curSettings };

  if (isLocked && gameSettings) {
    settings.player1_kind = gameSettings.players[1].kind;
    settings.player2_kind = gameSettings.players[2].kind;
    settings.player3_kind = gameSettings.players[3].kind;
    settings.showOppCards = gameSettings.showOppCards;
    settings.spades = gameSettings.spades;
  }

  return settings;
};
