import Paper from "@material-ui/core/Paper";
import React from "react";
import { TComponentProps } from "./RoomsTypes";

class Rooms extends React.Component<TComponentProps> {

  public render() {
    return (
      <Paper elevation={5}>
        <div className="rooms" data-disabled={this.props.locked} >
          Rooms
        </div>
      </Paper>
    );
  }
}

export default Rooms;
