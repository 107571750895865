import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "../components/App/AppContainer";
import Error from "../components/Error/Error";
// import Login from "../components/Login/Login";
// import LoginCallback from "../components/LoginCallback/LoginCallback";

export default class Router extends React.Component {
  public render() {
    return <BrowserRouter>
      <Switch>
        {/* use NavLink for navigation menu */}
        <Route path="/" component={App} exact />
        {/* <Route path="/login" component={Login} />
        <Route path="/oauth_callback" component={LoginCallback} />
        <Route path="/nokadasoft" component={() => {
          window.location.href = "https://www.nokadasoft.com";
          return null;
        }} /> */}
        <Route component={Error} />
      </Switch>
    </BrowserRouter>;
  }
}
