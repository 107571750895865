import { Component } from "ecsy";

export default class CardComponent extends Component {
  public static RANK_UNKNOWN = 0;
  public static RANK_NINE = 1;
  public static RANK_TEN = 2;
  public static RANK_JACK = 3;
  public static RANK_QUEEN = 4;
  public static RANK_KING = 5;
  public static RANK_ACE = 6;

  public static SUIT_UNKNOWN = 0;
  public static SUIT_SPADE = 1;
  public static SUIT_CLUB = 2;
  public static SUIT_DIAMOND = 3;
  public static SUIT_HEART = 4;

  public static BACKSIDE = "backside";

  public static getRankValue(rank: string): number {
    switch (rank) {
      case "n": return this.RANK_NINE;
      case "t": return this.RANK_TEN;
      case "j": return this.RANK_JACK;
      case "q": return this.RANK_QUEEN;
      case "k": return this.RANK_KING;
      case "a": return this.RANK_ACE;
      default: return this.RANK_UNKNOWN;
    }
  }

  public static getRankSymbol(rank: number): string {
    switch (rank) {
      case this.RANK_NINE: return "n";
      case this.RANK_TEN: return "t";
      case this.RANK_JACK: return "j";
      case this.RANK_QUEEN: return "q";
      case this.RANK_KING: return "k";
      case this.RANK_ACE: return "a";
      default: return "u";
    }
  }

  public static getSuitValue(suit: string): number {
    switch (suit) {
      case "s": return this.SUIT_SPADE;
      case "c": return this.SUIT_CLUB;
      case "d": return this.SUIT_DIAMOND;
      case "h": return this.SUIT_HEART;
      default: return this.SUIT_UNKNOWN;
    }
  }

  public static getSuitSymbol(suit: number): string {
    switch (suit) {
      case this.SUIT_SPADE: return "s";
      case this.SUIT_CLUB: return "c";
      case this.SUIT_DIAMOND: return "d";
      case this.SUIT_HEART: return "h";
      default: return "u";
    }
  }

  private static buildSymbol(rank: number, suit: number): string {
    let newSymbol = `${CardComponent.getRankSymbol(rank)}${CardComponent.getSuitSymbol(suit)}`;
    if (newSymbol === "uu") {
      newSymbol = CardComponent.BACKSIDE;
    }
    return newSymbol;
  }

  public id: string = "";
  public rank: number = -1;
  public suit: number = -1;
  public symbol: string = "";
  public asset: string = "";

  public reset() {
    this.id = "";
    this.rank = -1;
    this.suit = -1;
    this.symbol = "";
    this.asset = "";
  }

  public setFromSymbol(symbol: string) {
    symbol = symbol.toLowerCase();
    if (symbol.length === 2) {
      this.rank = CardComponent.getRankValue(symbol[0]);
      this.suit = CardComponent.getSuitValue(symbol[1]);
      this.symbol = CardComponent.buildSymbol(this.rank, this.suit);
      this.asset = this.symbol;
      if (this.rank !== CardComponent.RANK_UNKNOWN && this.suit !== CardComponent.SUIT_UNKNOWN) {
        return;
      }
    }
    this.rank = CardComponent.RANK_UNKNOWN;
    this.suit = CardComponent.SUIT_UNKNOWN;
    this.symbol = CardComponent.buildSymbol(this.rank, this.suit);
    this.asset = CardComponent.BACKSIDE;
  }

  public faceUp() {
    this.asset = this.symbol;
  }

  public faceDown() {
    this.asset = CardComponent.BACKSIDE;
  }
}
