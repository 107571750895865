import { AppBar, createStyles, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Theme, Toolbar, Typography, withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Slide, { SlideProps } from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import IdleTimer from "react-idle-timer";
import * as constants from "../../common/constants";
import * as customTheme from "../../styles/theme";
import Board from "../Board/BoardContainer";
import Footer from "../Footer/Footer";
import Header from "../Header/HeaderContainer";
import Notifications from "../Notifications/NotificationsContainer";
import Rooms from "../Rooms/RoomsContainer";
import Settings from "../Settings/SettingsContainer";
import Status from "../Status/StatusContainer";
import ArchitectureDesignLink from "../ArchitectureDesignLink/ArchitectureDesignLinkContainer";
import WebSocketConnection from "../WebSocketConnection/WebSocketConnectionContainer";
import { IComponentState, TComponentProps } from "./AppTypes";

const useStyles = (theme: Theme) => createStyles({
  appBar: customTheme.appBar,
  dialog: {
    flex: "1 1 auto",
    backgroundColor: theme.palette.background.default,
  },
  title: {
    flex: 1,
  },
});

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => {
  return <Slide direction="right" ref={ref} {...props} />;
});

export class App extends React.Component<TComponentProps, IComponentState> {
  private idleTimer: any = null;
  private idleCountdownTimer: any = null;

  constructor(props: TComponentProps) {
    super(props);
    this.state = {
      idleCountdownStart: 0,
      idleCountdownCurrent: 0,
    };
  }

  public handleMobileSlideClose = () => { this.props.setMobileSlideOff(); };

  public componentDidMount() {
    window.addEventListener("resize", this.resize);
    // todo: window.addEventListener("beforeunload", this.refresh); <== alert before refreshing the page!
  }
  public componentWillUnmount() { window.removeEventListener("resize", this.resize); }
  public resize = () => { this.forceUpdate(); };

  // public componentWillUnmount() { window.removeEventListener("beforeunload", this.refresh); }
  public refresh = (event: any) => {
    event.preventDefault(); // Cancel the event as stated by the standard.
    event.returnValue = ""; // Chrome requires returnValue to be set.
  }

  // https://www.npmjs.com/package/react-notification-system
  public render() {
    let gameLayout;
    const { classes } = this.props;

    const cc = this.state.idleCountdownCurrent;
    const disconnectionText = cc === 0 ? "game has been disconnected" : `game will disconnect in ${cc} seconds...`;

    const idleDialog = <Dialog
      open={this.props.isIdle}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">user inactivity detected</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {disconnectionText}
        </DialogContentText>
      </DialogContent>
    </Dialog>;

    if (this.props.isMobile) {

      // https://jsfiddle.net/p7f3bcm6/1/
      // https://swizec.com/blog/ref-callbacks-measure-react-component-size/swizec/8444

      // <Dialog fullScreen open={this.props.mobileSlide} onClose={this.handleMobileSlideClose} TransitionComponent={Transition}>
      gameLayout = <div className="mobile-container" >
        <Dialog fullScreen open={this.props.mobileSlide} onClose={this.handleMobileSlideClose}>
          <div className={classes.dialog}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={this.handleMobileSlideClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography className={classes.title}></Typography>
              </Toolbar>
            </AppBar>
            <div style={{ margin: "5px 5px 5px 5px" }}>
              <Status />
              <Settings />
              <ArchitectureDesignLink />
              <Rooms />
            </div>
          </div>
        </Dialog>

        <div className="mobile-header">
          <Header />
        </div>

        <div className="mobile-board">
          <Paper className="mobile-board-paper" elevation={5}>
            <Board />
          </Paper>
        </div>

        <div className="mobile-footer">
          <Footer />
        </div>
      </div>;

    } else {
      gameLayout = <div>
        <Header />
        <div className="board-panes-content">
          <div className="board-left-panes">
            <Status />
            <Settings />
            <ArchitectureDesignLink />
            <Rooms />
          </div>
          <Paper className={"board-paper"} elevation={5} >
            <Board />
          </Paper>
        </div>
        <Footer />
      </div>;
    }

    return <div>
      <WebSocketConnection>
        <IdleTimer
          ref={(ref: any) => { this.idleTimer = ref; }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={0}
          timeout={constants.IDLE_TIME_DETECTION} />
        {idleDialog}
        {gameLayout}
        <Notifications />
      </WebSocketConnection>
    </div>;
  }

  private onAction = (e: any) => { return; };

  private onActive = (e: any) => {
    console.log("user is active");
    if (this.idleCountdownTimer) {
      clearInterval(this.idleCountdownTimer);
      this.idleCountdownTimer = undefined;
    }
    this.props.setIdle(false);
    if (!this.props.isConnected) {
      this.props.connect();
    }
  }

  private onIdle = (e: any) => {
    console.log("user is idle");
    this.props.setIdle(true);

    this.setState({
      idleCountdownStart: performance.now(),
      idleCountdownCurrent: constants.IDLE_TIME_DIALOG,
    });
    this.idleCountdownTimer = setInterval(() => {
      let cc = constants.IDLE_TIME_DIALOG - Math.floor((performance.now() - this.state.idleCountdownStart) / 1000);
      if (cc < 0) { cc = 0; }
      this.setState({
        ...this.state,
        idleCountdownCurrent: cc,
      });

      if (cc <= 0) {
        clearInterval(this.idleCountdownTimer);
        this.props.gameQuit();
        this.props.disconnect();
      }
    }, 500);
  }

}

export default withStyles(useStyles)(App);
