import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as appActions from "../../redux/modules/app";
import { appIdleAction } from "../../redux/modules/app";
import { gameUserStopAction } from "../../redux/modules/game";
import * as webSocketActions from "../../redux/modules/webSocket";
import { EWebSocketStateTypes } from "../../redux/modules/webSocketTypes";
import { IStoreState } from "../../redux/storeTypes";
import App from "./App";
import { IMapDispatchToProps, IMapStateToProps } from "./AppTypes";

const mapStateToProps = (state: IStoreState): IMapStateToProps => ({
  isConnected: state.webSocket.socketState === EWebSocketStateTypes.WEBSOCKET_STATE_CONNECTED,
  isMobile: state.app.isMobile,
  mobileSlide: state.app.mobileSlide,
  isIdle: state.app.idle,
});

const mapDispatchToProps = (dispatch: Dispatch): IMapDispatchToProps => ({
  connect: () => dispatch(webSocketActions.webSocketConnect()),
  disconnect: () => dispatch(webSocketActions.webSocketDisconnect()),
  setMobileSlideOff: () => dispatch(appActions.appMobileSlideAction(false)),
  gameQuit: () => dispatch(gameUserStopAction()),
  setIdle: (idle: boolean) => dispatch(appIdleAction(idle)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
