import { Entity } from "ecsy";
import CardComponent from "../components/cardComponent";
import ClickedComponent from "../components/clickedComponent";
import DraggedComponent from "../components/draggedComponent";
import InteractiveComponent from "../components/interactiveComponent";
import PositionOriginComponent from "../components/positionOriginComponent";
import CollocationManager from "../managers/collocationManager";
import GameStateManager from "../managers/gameStateManager";
import { ICardPosition } from "../types/types";
import { AnimTypes } from "./constants";
import { applyTweenForDragged, cancelTweenForPosition } from "./tweenHelper";
import { sortEntities } from "./utils";

export const grabCardsFromTable = (
  collocationManager: CollocationManager,
  gameStateManager: GameStateManager,
  interactiveEntity: Entity,
  tableEntities: Entity[],
  playerCardsCount: number) => {

  const TIME_SHORTCLICK_ANIM = gameStateManager.getAnimTime(AnimTypes.UP_ON_SHORTCLICK);

  const cardId = interactiveEntity.getComponent(CardComponent).id;
  const cards = sortEntities(tableEntities.filter((entity) => entity.hasComponent(InteractiveComponent)));
  const clickedIndex = cards.findIndex((item) => item.entity.getComponent(CardComponent).id === cardId);

  // clicked one.................
  const ctClicked = cancelTweenForPosition(interactiveEntity);
  interactiveEntity.addComponent(ClickedComponent);
  interactiveEntity.addComponent(PositionOriginComponent, ctClicked.targetPosition ? ctClicked.targetPosition : { ...ctClicked.currentPosition });
  interactiveEntity.addComponent(DraggedComponent, ctClicked.currentPosition);

  const targetPositionClicked: ICardPosition = {
    x: ctClicked.currentPosition.x,
    y: ctClicked.currentPosition.y,
    angle: 0,
    zIndex: ctClicked.currentPosition.zIndex,
  };

  applyTweenForDragged(interactiveEntity, ctClicked.currentPosition, targetPositionClicked, TIME_SHORTCLICK_ANIM);
  // ............................

  const draggedPositions = collocationManager.getDraggedPositions(cards.length, 0, cards.length + playerCardsCount);

  cards.forEach((item, index) => {
    const entity = item.entity;
    const draggedPosition = draggedPositions[index];
    if (index === clickedIndex) { return; }

    const targetPosition: ICardPosition = {
      x: draggedPosition.x - draggedPositions[clickedIndex].x + ctClicked.currentPosition.x,
      y: draggedPosition.y - draggedPositions[clickedIndex].y + ctClicked.currentPosition.y,
      angle: draggedPosition.angle,
      zIndex: ctClicked.currentPosition.zIndex,
    };

    const ct = cancelTweenForPosition(entity);
    entity.addComponent(PositionOriginComponent, ct.targetPosition ? ct.targetPosition : ct.currentPosition);

    const draggedComponent = { ...ct.currentPosition };
    entity.addComponent(DraggedComponent, draggedComponent);

    applyTweenForDragged(entity, ct.currentPosition, targetPosition, TIME_SHORTCLICK_ANIM);
  });
};
