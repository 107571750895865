import { EDifficulty, EKind, ESpades } from "./settingsTypes";

export enum EServerMessageType {
  ERROR = 99,
  CLIENTID = 100,
  START = 101,
  MOVE = 102,
  STOP = 103,
}

export enum EStopReason {
  QUIT = 0,
  ROUND_END = 1,
  GAME_SCORE = 2,
}

// ERROR
export interface IPayloadError {
  error: string;
}

export interface IServerMessageError {
  type: typeof EServerMessageType.ERROR;
  payload: IPayloadError;
}

// CONNECTED
export interface IPayloadConnected {
  clientId: string;
}

export interface IServerMessageClientId {
  type: typeof EServerMessageType.CLIENTID;
  payload: IPayloadConnected;
}

// START
export interface IPlayer {
  clientId?: string;
  kind: EKind;
  cards: string[];
}

export interface IPayloadStart {
  gameId: string;
  difficulty: EDifficulty;
  spades: ESpades;
  players: IPlayer[];
  showOppCards: boolean;
  nextPlayer: number;
}

export interface IServerMessageStart {
  type: typeof EServerMessageType.START;
  payload: IPayloadStart;
}

// MOVE
export interface IPayloadMove {
  player: number;
  taking: boolean;
  cards: string[];
  nextPlayer: number;
}

export interface IServerMessageMove {
  type: typeof EServerMessageType.MOVE;
  payload: IPayloadMove;
}

// STOP
export interface IScorePayload {
  losers: number[]; // an array of losers
}

export interface IPayloadStop {
  reason: EStopReason;
  player: number;
  score?: IScorePayload;
}

export interface IServerMessageStop {
  type: typeof EServerMessageType.STOP;
  payload: IPayloadStop;
}

// WebSocket server messages
export type TServerMessage = IServerMessageError | IServerMessageClientId | IServerMessageStart | IServerMessageMove | IServerMessageStop;
