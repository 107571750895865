import { Link } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React from "react";
import { IMG_ARCHITECTUREDESIGN, URL_ARCHITECTUREDESIGN } from "../../common/constants";
import { EGameStage } from "../../redux/modules/gameTypes";
import { TComponentProps } from "./ArchitectureDesignLinkTypes";
import "./architectureDesignLink.scss";

class ArchitectureDesignLink extends React.Component<TComponentProps> {

  public handleClick = () => {
    const { gameStart, gameQuit, mobileSlide, setMobileSlideOff } = this.props;
    if (this.props.gameStage === EGameStage.STOPPED) {
      gameStart();
      if (mobileSlide) { setMobileSlideOff(); }
    } else {
      gameQuit();
    }
  }

  public render() {
    const logoImage = require("../../assets/" + IMG_ARCHITECTUREDESIGN);

    return (
      <Paper elevation={5}>
        <div className="architecture">
          <Link href={URL_ARCHITECTUREDESIGN}>
            <img src={logoImage} alt="logo" style={{ maxWidth: "100%", maxHeight: "100%" }} />
          </Link>
        </div>
      </Paper>
    );
  }
}

export default ArchitectureDesignLink;
