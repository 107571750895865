import { createStyles, Link, Theme, Typography, withStyles } from "@material-ui/core";
import React from "react";
import { URL_NOKADASOFT } from "../../common/constants";
import { TComponentProps } from "./FooterTypes";

const useStyles = (theme: Theme) => createStyles({
  root: {
    color: theme.palette.primary.main,
    margin: "0 0 5px 0",
  },
});

class Footer extends React.Component<TComponentProps> {
  public render() {
    return <div className="footer">
      <Typography className={this.props.classes.root}>
        <Link href={URL_NOKADASOFT} >NokadaSoft</Link> 2024 (since 2019)
      </Typography>
    </div>;
  }
}

export default withStyles(useStyles)(Footer);
