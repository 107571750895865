import { AnyAction, Dispatch, Middleware } from "redux";
// import { EServerStatus } from "../common/constants";
import { EKind } from "../../commonClientServer/settingsTypes";
import { SETTINGS_INITIAL_STATE } from "../modules/settings";
// import * as serverConnectionTypes from "../redux/serverConnection/serverConnectionTypes";
import * as settingsTypes from "../modules/settingsTypes";

// export const saveSettingsToStoreMiddleware = (store: IStore) => (next: Dispatch) => (action: AnyAction) => {
export const saveToStoreMiddleware: Middleware = (store: any) => (next: Dispatch) => (action: AnyAction) => {
  switch (action.type) {
    case settingsTypes.EActionTypes.SETTINGS_SET_ALL:
      localStorage.setItem("pan-settings", JSON.stringify(action.settings));
      break;

    // case serverConnectionTypes.EActionTypes.WEBSOCKET_CONNECTED:
    //   if (action.clientId) {
    //     localStorage.setItem("pan-serverconnection", JSON.stringify({ clientId: action.clientId }));
    //   }
    //   break;

    default: break;
  }
  return next(action);
};

export const loadFromStore = () => {
  let storageData = {};

  const settings = loadSettingsFromStore();
  if (settings) { storageData = { ...storageData, settings }; }

  // const serverConnection = loadServerConnectionFromStore();
  // if (serverConnection) { storageData = { ...storageData, serverConnection }; }

  return storageData;
};

const loadSettingsFromStore = () => {
  const storageData = localStorage.getItem("pan-settings");
  // console.log("LOCALSTORAGE - LOAD pan-settings:");
  if (storageData !== null) {
    try {
      const data = JSON.parse(storageData);
      const settings = { ...SETTINGS_INITIAL_STATE };
      if (data.difficulty !== undefined) { settings.difficulty = data.difficulty; }
      if (data.spades !== undefined) { settings.spades = data.spades; }
      if (data.name !== undefined) { settings.name = data.name.substring(0, 20); }
      if (data.player1_kind !== undefined) { settings.player1_kind = data.player1_kind; }
      if (data.player2_kind !== undefined) { settings.player2_kind = data.player2_kind; }
      if (data.player3_kind !== undefined) { settings.player3_kind = data.player3_kind; }
      if (data.showOppCards !== undefined) { settings.showOppCards = data.showOppCards; }
      if (data.sorting !== undefined) { settings.sorting = data.sorting; }
      if (data.speed !== undefined) { settings.speed = data.speed; }
      if (data.notifications !== undefined) { settings.notifications = data.notifications; }
      validateSettings(settings);
      return settings;
    } catch (error) {
      console.error(error);
      localStorage.removeItem("pan-settings");
      localStorage.clear();
    }
  }
  return undefined;
};

function validateSettings(settings: settingsTypes.ISettingsState) {
  if ([0, 1].indexOf(settings.difficulty) === -1) {
    throw new Error("invalid settings.difficulty in local storage");
  }
  if ([0, 1, 2].indexOf(settings.spades) === -1) {
    throw new Error("invalid settings.spades in local storage");
  }
  if (!(settings.player1_kind in EKind)) { throw new Error("invalid settings.player1_kind in local storage"); }
  if (!(settings.player2_kind in EKind)) { throw new Error("invalid settings.player2_kind in local storage"); }
  if (!(settings.player3_kind in EKind)) { throw new Error("invalid settings.player3_kind in local storage"); }
}

// const loadServerConnectionFromStore = () => {
//   const storageData = localStorage.getItem("pan-serverconnection");
//   if (storageData !== null) {
//     try {
//       const data = JSON.parse(storageData);
//       const serverConnection: serverConnectionTypes.IWebSocketState = {
//         socket: EServerStatus.DISCONNECTED,
//         clientId: data.clientId,
//       };
//       validateServerConnection(serverConnection);
//       return serverConnection;
//     } catch (error) {
//       console.error(error);
//       localStorage.removeItem("pan-serverconnection");
//     }
//   }
//   return undefined;
// };

// function validateServerConnection(serverConnection: serverConnectionTypes.IWebSocketState) {
//   if (serverConnection.clientId !== null && serverConnection.clientId!.length !== 36) {
//     throw new Error("invalid serverConnection.clientId in local storage");
//   }
// }
