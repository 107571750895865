import { Component } from "ecsy";

export default class TableOddsComponent extends Component {
  public rndX: number = 0;
  public rndY: number = 0;
  public rndAngle: number = 0;

  public copy(src: TableOddsComponent) {
    this.rndX = src.rndX;
    this.rndY = src.rndY;
    this.rndAngle = src.rndAngle;
  }

  public reset() {
    this.rndX = 0;
    this.rndY = 0;
    this.rndAngle = 0;
  }
}
