import { combineReducers } from "redux";
import { IStoreState } from "../storeTypes";
import app from "./app";
import game from "./game";
import notifications from "./notifications";
import settings from "./settings";
import webSocket from "./webSocket";

export default combineReducers<IStoreState>({
  app,
  settings,
  game,
  webSocket,
  notifications,
} as any);
