import { Reducer } from "redux";
import { NOTIFICATIONS_MAX_COUNT } from "../../common/constants";
import { EActionTypes, INotificationPayload, INotificationsClearAllAction, INotificationsHideAction, INotificationsShowAction, TNotificationsActions } from "../modules/notificationsTypes";

export const NOTIFICATIONS_INITIAL_STATE: INotificationPayload[] = [];

const reducer: Reducer<INotificationPayload[], TNotificationsActions> = (state = NOTIFICATIONS_INITIAL_STATE, action): INotificationPayload[] => {
  switch (action.type) {
    case EActionTypes.NOTIFICATIONS_SHOW:
      const newState = [
        ...state,
        action.notification,
      ];

      if (newState.length > NOTIFICATIONS_MAX_COUNT) {
        newState.splice(0, newState.length - NOTIFICATIONS_MAX_COUNT);
      }

      return newState;

    case EActionTypes.NOTIFICATIONS_HIDE:
      return state.filter((item) => item.uid !== action.uid);

    case EActionTypes.NOTIFICATIONS_CLEAR_ALL:
      return [];

    default:
      return state;
  }
};

export default reducer;

export function show(notification: INotificationPayload): INotificationsShowAction {
  return {
    type: EActionTypes.NOTIFICATIONS_SHOW,
    notification: {
      ...notification,
      uid: notification.uid || Date.now(),
    },
  };
}

export function hide(uid: number): INotificationsHideAction {
  return {
    type: EActionTypes.NOTIFICATIONS_HIDE,
    uid,
  };
}

export function clearAll(): INotificationsClearAllAction {
  return { type: EActionTypes.NOTIFICATIONS_CLEAR_ALL };
}
