import { EAppActionTypes, IAppFinishDialogAction,
   IAppIdleAction, IAppIsMobileAction, IAppMobileSlideAction, IAppState, TAppAction } from "./appTypes";

export const APP_INITIAL_STATE: IAppState = {
  isMobile: false,
  mobileSlide: false,
  idle: false,
  finishDialog: false,
  finishDialogTitle: "",
  finishDialogInfo1: "",
  finishDialogInfo2: "",
};

const reducer = (state: IAppState = APP_INITIAL_STATE, action: TAppAction) => {
  switch (action.type) {

    case EAppActionTypes.APP_IS_MOBILE: {
      const a = action as IAppIsMobileAction;
      return {
        ...state,
        isMobile: a.isMobile,
      };
    }

    case EAppActionTypes.APP_MOBILE_SLIDE: {
      const a = action as IAppMobileSlideAction;
      return {
        ...state,
        mobileSlide: a.mobileSlide,
      };
    }

    case EAppActionTypes.APP_IDLE: {
      const a = action as IAppIdleAction;
      return {
        ...state,
        idle: a.idle,
      };
    }

    case EAppActionTypes.APP_FINISH_DIALOG: {
      const a = action as IAppFinishDialogAction;
      return {
        ...state,
        finishDialog: a.finishDialog,
        finishDialogTitle: a.finishDialogTitle,
        finishDialogInfo1: a.finishDialogInfo1,
        finishDialogInfo2: a.finishDialogInfo2,
      };
    }

    default:
      return state;
  }
};

export default reducer;

export const appIsMobileAction = (isMobile: boolean): TAppAction =>
  ({ type: EAppActionTypes.APP_IS_MOBILE, isMobile });

export const appMobileSlideAction = (mobileSlide: boolean): TAppAction =>
  ({ type: EAppActionTypes.APP_MOBILE_SLIDE, mobileSlide });

export const appIdleAction = (idle: boolean): TAppAction =>
  ({ type: EAppActionTypes.APP_IDLE, idle });

export const appFinishDialogAction = (finishDialog: boolean, finishDialogTitle: string, finishDialogInfo1: string, finishDialogInfo2: string): TAppAction =>
  ({ type: EAppActionTypes.APP_FINISH_DIALOG, finishDialog, finishDialogTitle, finishDialogInfo1, finishDialogInfo2 });
