import { Entity } from "ecsy";
import CardComponent from "../components/cardComponent";
import ClickedComponent from "../components/clickedComponent";
import DraggedComponent from "../components/draggedComponent";
import PositionOriginComponent from "../components/positionOriginComponent";
import CollocationManager from "../managers/collocationManager";
import GameStateManager from "../managers/gameStateManager";
import { ICardPosition } from "../types/types";
import { AnimTypes } from "./constants";
import { applyTweenForDragged, applyTweenForPositionWithCancel, cancelTweenForPosition } from "./tweenHelper";
import { sortEntities } from "./utils";

export const grabFirstCardFromPlayer = (
  collocationManager: CollocationManager,
  gameStateManager: GameStateManager,
  interactiveEntity: Entity) => {

  const TIME_SHORTCLICK_ANIM = gameStateManager.getAnimTime(AnimTypes.UP_ON_SHORTCLICK);

  interactiveEntity.addComponent(ClickedComponent);

  const ct = cancelTweenForPosition(interactiveEntity);
  interactiveEntity.addComponent(PositionOriginComponent, ct.targetPosition ? ct.targetPosition : { ...ct.currentPosition });
  interactiveEntity.addComponent(DraggedComponent, ct.currentPosition);

  let targetOffsetY = 0;
  if (!ct.targetPosition) {
    targetOffsetY = -collocationManager.getCardSize(1).h / 4;
  }

  const targetPosition: ICardPosition = {
    x: ct.currentPosition.x,
    y: ct.currentPosition.y + targetOffsetY,
    angle: 0,
    zIndex: ct.currentPosition.zIndex,
  };

  applyTweenForDragged(interactiveEntity, ct.currentPosition, targetPosition, TIME_SHORTCLICK_ANIM);
};

export const grabRestCardsFromPlayer = (
  collocationManager: CollocationManager,
  gameStateManager: GameStateManager,
  clickedEntity: Entity,
  playerSouthEntities: Entity[]) => {

  const TIME_LONGCLICK_ANIM = gameStateManager.getAnimTime(AnimTypes.UP_ON_LONGCLICK);

  const clickedCardComponent = clickedEntity.getComponent(CardComponent);
  if (clickedCardComponent.rank === CardComponent.RANK_NINE && clickedCardComponent.suit === CardComponent.SUIT_HEART) { return; }

  let thereWas9H = false;
  const dragIndexes: number[] = [];
  const notDragIndexes: number[] = [];

  const cards = sortEntities(playerSouthEntities)
    .map((item, index) => {
      const cardComponent = item.entity.getComponent(CardComponent);
      if (cardComponent.rank === clickedCardComponent.rank) { dragIndexes.push(index); } else { notDragIndexes.push(index); }
      if (cardComponent.rank === CardComponent.RANK_NINE && cardComponent.suit === CardComponent.SUIT_HEART) { thereWas9H = true; }
      return { ...item, cardComponent };
    });

  if ((clickedCardComponent.rank === CardComponent.RANK_NINE && thereWas9H) ||
    dragIndexes.length === 0 ||
    (clickedCardComponent.rank === CardComponent.RANK_NINE && dragIndexes.length !== 3) ||
    (clickedCardComponent.rank !== CardComponent.RANK_NINE && dragIndexes.length !== 4)) {
    return;
  }

  const newPlayerPositions = collocationManager.getPlayerPositionsAsRow(1, cards.length);

  const cardsClickedIndex = cards.findIndex((item) => item.cardComponent.id === clickedCardComponent.id);
  const dragIndexesClickedIndex = dragIndexes.findIndex((i) => i === cardsClickedIndex);
  const newFirstDragCardsIndex = cardsClickedIndex - dragIndexesClickedIndex;

  const newDragIndexes = dragIndexes.map((i, index) => newFirstDragCardsIndex + index);
  const newNotDragIndexes = notDragIndexes.map((i, index) => {
    if (index < newFirstDragCardsIndex) {
      return index;
    } else {
      return index + dragIndexes.length;
    }
  });

  const draggedPositions = collocationManager.getDraggedPositions(dragIndexes.length, 0, newPlayerPositions.length);
  const clickedDragComponent = clickedEntity.getComponent(DraggedComponent);

  dragIndexes.forEach((i, index) => {
    const entity = cards[i].entity;
    const newPlayerPosition = newPlayerPositions[newDragIndexes[index]];
    const draggedPosition = draggedPositions[index];

    if (i === cardsClickedIndex) {
      entity.getMutableComponent(DraggedComponent).zIndex = newPlayerPosition.zIndex;
      return;
    }

    const targetPosition: ICardPosition = {
      x: draggedPosition.x - draggedPositions[dragIndexesClickedIndex].x + clickedDragComponent.x,
      y: draggedPosition.y - draggedPositions[dragIndexesClickedIndex].y + clickedDragComponent.y,
      angle: draggedPosition.angle,
      zIndex: newPlayerPosition.zIndex,
    };

    const ct = cancelTweenForPosition(entity);
    entity.addComponent(PositionOriginComponent, newPlayerPosition);

    const draggedComponent = { ...ct.currentPosition };
    draggedComponent.zIndex = newPlayerPosition.zIndex;
    entity.addComponent(DraggedComponent, draggedComponent);

    applyTweenForDragged(entity, ct.currentPosition, targetPosition, TIME_LONGCLICK_ANIM);
  });

  // not dragged, but potentially animated
  notDragIndexes.forEach((i, index) => {
    applyTweenForPositionWithCancel(cards[i].entity, newPlayerPositions[newNotDragIndexes[index]], TIME_LONGCLICK_ANIM);
  });
};
