import { Entity } from "ecsy";
import { AnimTypes } from "../common/constants";
import { applyTweenForDragged, applyTweenForPositionWithCancel } from "../common/tweenHelper";
import { ISortedEntityItem, sortEntities } from "../common/utils";
import CardComponent from "../components/cardComponent";
import ClickedComponent from "../components/clickedComponent";
import DraggedComponent from "../components/draggedComponent";
import ManagersComponent from "../components/managersComponent";
import PlayerSouthComponent from "../components/playerSouthComponent";
import PositionComponent from "../components/positionComponent";
import PositionOriginComponent from "../components/positionOriginComponent";
import SnappedToTargetComponent from "../components/snappedToTargetComponent";
import TableComponent from "../components/tableComponent";
import TableOddsComponent from "../components/tableOddsComponent";
import TweenComponent from "../components/tweenComponent";
import { ICardPosition } from "../types/types";

export const draggedSystemExecute = (
  delta: number, time: number,
  common: ManagersComponent,
  qrDragged: Entity[],
  qrClicked: Entity[],
  qrPlayer: Entity[],
  qrTable: Entity[],
  qrTableDragged: Entity[]) => { // todo: sort this - not needed as we have full table anyway

  let playerCardsEligibleToSnap = false;
  const TIME_LONGCLICK_ANIM = common.gameStateManager.getAnimTime(AnimTypes.UP_ON_LONGCLICK);

  if (qrClicked.length === 1) {
    const clickedEntity = qrClicked[0];

    let cards: ISortedEntityItem[] = [];
    let newPlayerPositions: ICardPosition[] = [];
    let playerCardsGrabbed = false;

    if (clickedEntity.hasComponent(PlayerSouthComponent)) {
      if (common.gameStateManager.isUserTurn()) {
        const rankClicked = clickedEntity.getComponent(CardComponent).rank;
        const suitClicked = clickedEntity.getComponent(CardComponent).suit;
        playerCardsEligibleToSnap = rankClicked === CardComponent.RANK_NINE && suitClicked === CardComponent.SUIT_HEART;
        if (!playerCardsEligibleToSnap && qrTable.length > 0) {
          const rankTable = sortEntities(qrTable)[qrTable.length - 1].entity.getComponent(CardComponent).rank;
          playerCardsEligibleToSnap = rankClicked >= rankTable;
        }
      }

      // when player cards dragged
      newPlayerPositions = common.collocationManager.getPlayerPositionsAsRow(1, qrPlayer.length);
      qrPlayer.forEach((entity) => entity.addComponent(TableOddsComponent, { rndX: Math.random(), rndY: Math.random(), rndAngle: Math.random() }));

      cards = sortEntities(qrPlayer);

      playerCardsGrabbed = true;

    } else if (clickedEntity.hasComponent(TableComponent) && qrPlayer.length > 0) {
      // when table cards dragged
      const curPlayerPositions = common.collocationManager.getPlayerPositionsAsRow(1, qrPlayer.length);
      newPlayerPositions = common.collocationManager.getPlayerPositionsAsRow(1, qrPlayer.length + qrTableDragged.length);

      if (!qrPlayer[0].hasComponent(PositionOriginComponent)) {
        sortEntities(qrPlayer)
          .forEach((item, index) => {
            item.entity.addComponent(PositionOriginComponent, { ...curPlayerPositions[index] });
            applyTweenForPositionWithCancel(item.entity, newPlayerPositions[index], TIME_LONGCLICK_ANIM, 25 * index);
          });

        sortEntities(qrTableDragged)
          .forEach((item, index) => {
            const zIndex = newPlayerPositions[qrPlayer.length + index].zIndex;
            const positionComponent = item.entity.getMutableComponent(PositionComponent);
            positionComponent.zIndex = zIndex;
            const draggedComponent = item.entity.getMutableComponent(DraggedComponent);
            draggedComponent.zIndex = zIndex;
          });
      }

      const playerAndTable = qrPlayer.map((entity) => entity);
      qrTableDragged.forEach((entity) => { playerAndTable.push(entity); });
      cards = sortEntities(playerAndTable);
    }

    const dragDelta = common.inputControlManager.getDelta();

    if (cards.length > 0) {
      const dragIndexes: number[] = [];
      let dragIndexesClickedIndex: number = -1;
      cards.forEach((item, index) => {
        if (item.entity.hasComponent(ClickedComponent)) {
          dragIndexesClickedIndex = index;
        }
        if (item.entity.hasComponent(DraggedComponent)) {
          dragIndexes.push(index);
        }
      });

      if (!clickedEntity.hasComponent(SnappedToTargetComponent)) {
        const clickedDraggedPosition = newPlayerPositions[dragIndexesClickedIndex];
        const firstDragIndex = dragIndexes[0];
        const lastDragIndex = dragIndexes[dragIndexes.length - 1];
        const firstDraggedPosition = newPlayerPositions[firstDragIndex];
        const lastDraggedPosition = newPlayerPositions[lastDragIndex];

        let x;
        if (!clickedEntity.hasComponent(SnappedToTargetComponent) && clickedEntity.hasComponent(TweenComponent)) {
          x = clickedEntity.getComponent(TweenComponent).data.x + dragDelta.dx;
        } else {
          x = clickedEntity.getComponent(DraggedComponent).x + dragDelta.dx;
        }
        const leftDraggedX = x + (firstDraggedPosition.x - clickedDraggedPosition.x);
        const rightDraggedX = x + (lastDraggedPosition.x - clickedDraggedPosition.x);

        let toMoveShift = 0;
        let toMoveShiftSign = 0;
        let toMoveStart = -1;
        let toMoveStop = -1;
        for (let i = 0; i < firstDragIndex; i++) {
          if (newPlayerPositions[i].x >= leftDraggedX) {
            toMoveShift = i - dragIndexes[0];
            toMoveShiftSign = -1;
            toMoveStart = i;
            toMoveStop = dragIndexes[0] - 1;
            break;
          }
        }

        if (toMoveShift === 0) {
          for (let i = cards.length - 1; i > lastDragIndex; i--) {
            if (newPlayerPositions[i].x <= rightDraggedX) {
              toMoveShift = i - dragIndexes[dragIndexes.length - 1];
              toMoveShiftSign = +1;
              toMoveStart = dragIndexes[dragIndexes.length - 1] + 1;
              toMoveStop = i;
              break;
            }
          }
        }

        if (toMoveShift !== 0) { // MOVED SO REARRANGE ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
          if (toMoveShiftSign > 0) {
            for (let i = toMoveStart; i <= toMoveStop; i++) {
              // move not dragged cards
              applyTweenForPositionWithCancel(cards[i].entity, newPlayerPositions[i - (dragIndexes.length * toMoveShiftSign)], TIME_LONGCLICK_ANIM, 25 * (i - toMoveStart));
            }
          } else {
            for (let i = toMoveStop; i >= toMoveStart; i--) {
              // move not dragged cards
              applyTweenForPositionWithCancel(cards[i].entity, newPlayerPositions[i - (dragIndexes.length * toMoveShiftSign)], TIME_LONGCLICK_ANIM, 25 * (toMoveStop - i));
            }
          }

          for (let i = 0; i < dragIndexes.length; i++) {
            // move dragged cards
            const dragIndex = dragIndexes[i];
            const entity = cards[dragIndex].entity;

            const newPosition = newPlayerPositions[dragIndex + toMoveShift];

            entity.getMutableComponent(DraggedComponent).zIndex = newPosition.zIndex;

            if (playerCardsGrabbed) {
              // player will always result with permanent realignment
              const po = entity.getMutableComponent(PositionOriginComponent);
              po.x = newPosition.x;
              po.y = newPosition.y;
              po.angle = newPosition.angle;
              po.zIndex = newPosition.zIndex;
            }
          }
        }
      }

      // SNAP TO TARGET
      if (playerCardsEligibleToSnap && qrDragged.findIndex((entity) => entity.hasComponent(TweenComponent)) === -1) {
        if (playerCardsGrabbed) { // SNAP PLAYER CARDS ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
          const tableCentre = common.collocationManager.getTableCoordinatesAtCentre();
          const cardSize = common.collocationManager.getCardSize(1);

          let x;
          let y;

          const alreadySnapped = clickedEntity.hasComponent(SnappedToTargetComponent);
          if (!alreadySnapped) {
            const t = clickedEntity.getComponent(TweenComponent);
            if (t) {
              x = t.data.x + dragDelta.dx;
              y = t.data.y + dragDelta.dy;
            } else {
              const d = clickedEntity.getComponent(DraggedComponent);
              x = d.x + dragDelta.dx;
              y = d.y + dragDelta.dy;
            }
          } else {
            const d = clickedEntity.getComponent(DraggedComponent);
            x = d.x + dragDelta.dx;
            y = d.y + dragDelta.dy;
          }

          const draggedX = x;
          const draggedY = y;
          const distFromTarget = Math.hypot(tableCentre.centreX - draggedX, tableCentre.centreY - draggedY);

          if (distFromTarget <= (cardSize.h * 1.0)) {
            if (!alreadySnapped) {
              // snap
              qrDragged.forEach((entity) => {
                const position = common.collocationManager.getTablePositionAsRandomPile(entity.getComponent(TableOddsComponent), 0);
                position.zIndex = entity.getComponent(DraggedComponent).zIndex;
                entity.addComponent(SnappedToTargetComponent);
                applyTweenForPositionWithCancel(entity, position, TIME_LONGCLICK_ANIM);
              });
            }
          } else {
            if (alreadySnapped) {
              // unsnap
              qrDragged.forEach((entity) => {
                entity.removeComponent(SnappedToTargetComponent);

                const from = { ...entity.getComponent(PositionComponent) };
                from.x = from.x - dragDelta.dx;
                from.y = from.y - dragDelta.dy;

                const draggedComponent = entity.getMutableComponent(DraggedComponent);
                draggedComponent.zIndex = entity.getComponent(PositionOriginComponent).zIndex;

                applyTweenForDragged(
                  entity,
                  from,
                  { ...draggedComponent },
                  TIME_LONGCLICK_ANIM);

                draggedComponent.x = from.x;
                draggedComponent.y = from.y;
              });
            }
          }
        }
      }

    }

  }

  // click:     add ClickedComponent, add PositionOriginComponent, add DraggedComponent
  // longClick:                       add PositionOriginComponent, add DraggedComponent
  // unclick:   remove ClickedComponent, remove PositionOriginComponent, remove DraggedComponent
  if (qrDragged.length > 0) {
    const dragDelta = common.inputControlManager.getDelta();
    qrDragged.forEach((entity) => {
      if (!entity.hasComponent(SnappedToTargetComponent)) {
        const draggedComponent = entity.getComponent(DraggedComponent);
        const positionComponent = entity.getMutableComponent(PositionComponent);

        positionComponent.x = draggedComponent.x + dragDelta.dx;
        positionComponent.y = draggedComponent.y + dragDelta.dy;
        positionComponent.angle = draggedComponent.angle;
        positionComponent.zIndex = draggedComponent.zIndex;
      }
    });
  }
};
