import { EDifficulty, EKind, ESpades } from "./settingsTypes";

export enum EClientMessageType {
  START = 201,
  MOVE = 202,
  STOP = 203,
}

// START
interface IPlayerOutgoing {
  clientId?: string;
  kind: EKind;
}

export interface IPayloadStart {
  token?: string;
  difficulty: EDifficulty;
  spades: ESpades;
  players: IPlayerOutgoing[];
  showOppCards: boolean;
}

export interface IClientMessageStart {
  type: typeof EClientMessageType.START;
  payload: IPayloadStart;
}

// MOVE
export interface IPayloadMove {
  gameId: string;
  clientId: string;
  taking: boolean;
  cards: string[];
}

export interface IClientMessageMove {
  type: typeof EClientMessageType.MOVE;
  payload: IPayloadMove;
}

// STOP
export interface IPayloadStop {
  gameId: string;
  clientId: string;
}

export interface IClientMessageStop {
  type: typeof EClientMessageType.STOP;
  payload: IPayloadStop;
}

// WebSocket client messages
export type TClientMessage = IClientMessageStart | IClientMessageMove | IClientMessageStop;
