export default class AssetsManager {
  public assets: { [id: string]: HTMLImageElement } = {};
  private loaded: number = 0;
  private onAllLoaded: any = null;
  private width: number = 0;
  private height: number = 0;

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }

  public init(onLoaded: any) {
    this.onAllLoaded = onLoaded;

    // http://www.javascriptkit.com/javatutors/preloadimagesplus.shtml

    const ranks = ["n", "t", "j", "q", "k", "a"];
    const suits = ["h", "d", "c", "s"];
    ranks.forEach((r) => {
      suits.forEach((s) => {
        const symbol = r + s;
        this.assets[symbol] = this.loadImage(`card_${symbol}.png`);
      });
    });
    const symbolBackside = "backside";
    this.assets.backside = this.loadImage(`card_${symbolBackside}.png`);

    this.assets.button_sort = this.loadImage(`button_sort.png`);
    this.assets.current_player_indicator = this.loadImage(`current_player_indicator.png`);
  }

  private loadImage(asset: string) {
    const img = new Image();
    img.onload = () => this.onLoad();
    img.src = require(`../../assets/${asset}`);
    return img;
  }

  private onLoad() {
    this.loaded++;
    if (this.loaded === 27) {
      this.onAllLoaded();
    }
  }

}
