import { Entity } from "ecsy";
import ManagersComponent from "../components/managersComponent";

/*
.GameStateSystem
- get the first message from the queue if processing is F
- check if no ongoing tweens:
  1. TWEEN.getAll()
  2.
static animated = {
    renderables: {
      components: [CardComponent, TweenComponent]
    },
    common: {
      components: [ManagersComponent],
      mandatory: true
    }
  };
*/

export const gameStateSystemExecute = (
  delta: number, time: number,
  common: ManagersComponent,
  qrCommands: Entity[]) => {

  if (qrCommands.length === 0) {
    const message = common.gameStateManager.getMessage();
    if (message) {
      common.gameStateManager.processMessage(common, message);
    }
  }
};
