import { Dispatch } from "redux";
import * as constants from "../../common/constants";
import * as messagesCache from "../../common/messagesCache";
import { ECacheMessageType, EStopReason } from "../../common/messagesCacheTypes";
import { createMessageGameMoveRequest, createMessageGameStartRequest, createMessageGameStopRequest } from "../../common/messagesToServerHelper";
import { makeNotificationOnGameAction } from "../../common/utils";
import { appFinishDialogAction } from "../modules/app";
import { EGameActionTypes, TGameAction } from "../modules/gameTypes";
import { IGameServerMoveAction, IGameServerStartAction, IGameServerStopAction, IGameUserMoveAction, IGameUserStartAction, IGameUserStopAction } from "../modules/gameTypes";
import * as notificationsActions from "../modules/notifications";
import { webSocketSendMessage } from "../modules/webSocket";
import { IStore } from "../storeTypes";

// https://redux.js.org/advanced/middleware
// https://www.reddit.com/r/reactjs/comments/7cfgzr/redux_modifying_action_payload_in_middleware/ - update action payload in middleware
// https://www.codementor.io/vkarpov/beginner-s-guide-to-redux-middleware-du107uyud - set interval example

const gameMiddleware = (store: any) => (next: Dispatch) => (action: TGameAction) => {
  switch (action.type) {
    case EGameActionTypes.GAME_SERVER_START: gameServerStart(store, action as IGameServerStartAction); break;
    case EGameActionTypes.GAME_SERVER_STOP: gameServerStop(store, action as IGameServerStopAction); break;
    case EGameActionTypes.GAME_SERVER_MOVE: gameServerMove(store, action as IGameServerMoveAction); break;
    case EGameActionTypes.GAME_USER_START: gameUserStart(store, action as IGameUserStartAction); break;
    case EGameActionTypes.GAME_USER_STOP: gameUserStop(store, action as IGameUserStopAction); break;
    case EGameActionTypes.GAME_USER_MOVE: gameUserMove(store, action as IGameUserMoveAction); break;
    default: break;
  }
  return next(action);
};

const gameServerStart = (store: IStore, action: IGameServerStartAction) => {
  if (store.getState().settings.notifications) {
    store.dispatch(notificationsActions.show(makeNotificationOnGameAction("GAME STARTED")));
  }
};

const gameServerStop = (store: IStore, action: IGameServerStopAction) => {
  if (action.who === 0 && action.why === EStopReason.QUIT) { return; }
  if (store.getState().settings.notifications) {
    store.dispatch(notificationsActions.show(makeNotificationOnGameAction("GAME STOPPED by " + getPlayerName(action.who + 1))));
  }

  let title;
  let info1;
  let info2;
  if (action.who === 0) {
    title = `Game Over`;
    info1 = `You have lost the game`;
    info2 = `Good luck next time!`;
  } else {
    let player = "";
    switch (action.who) {
      case 1: player = "WEST"; break;
      case 2: player = "NORTH"; break;
      case 3: player = "EAST"; break;
      default: break;
    }

    if (action.why === EStopReason.QUIT) {
      title = "Game Ended";
      info1 = `Player ${player} has left the game`;
      info2 = "";
    } else {
      title = "Congratulations!";
      info1 = "You have won!";
      info2 = `Player ${player} has lost the game`;
    }
  }
  store.dispatch(appFinishDialogAction(true, title, info1, info2));
};

const gameServerMove = (store: IStore, action: IGameServerMoveAction) => {
  const oper = action.taking ? "took" : "put";
  const cards = action.cards.join(",");
  if (store.getState().settings.notifications) {
    store.dispatch(notificationsActions.show(makeNotificationOnGameAction(`${getPlayerName(action.player + 1)} ${oper} ${cards}`)));
  }
};

const gameUserStart = (store: IStore, action: IGameUserStartAction) => {
  store.dispatch(webSocketSendMessage(createMessageGameStartRequest(store)));
};

const gameUserStop = (store: IStore, action: IGameUserStopAction) => {
  store.dispatch(webSocketSendMessage(createMessageGameStopRequest(store)));
  messagesCache.clearMessages();
  messagesCache.addMessageToQueue(
    {
      type: ECacheMessageType.CACHE_MESSAGE_CLEAR,
      who: 0,
      why: EStopReason.QUIT,
      losers: undefined,
    });
  if (store.getState().settings.notifications) {
    store.dispatch(notificationsActions.show(makeNotificationOnGameAction("YOU STOPPED THE GAME")));
  }
};

const gameUserMove = (store: IStore, action: IGameUserMoveAction) => {
  store.dispatch(webSocketSendMessage(createMessageGameMoveRequest(
    store,
    action.taking,
    action.cards.map((card) => card.toUpperCase()),
  )));

  const oper = action.taking ? "took" : "put";
  const cards = action.cards.map((item) => item.toUpperCase()).join(",");
  if (store.getState().settings.notifications) {
    store.dispatch(notificationsActions.show(makeNotificationOnGameAction(`You ${oper} ${cards}`)));
  }
};

const getPlayerName = (player: number) => {
  switch (player) {
    case 2: return constants.PLAYER_NAME_WEST;
    case 3: return constants.PLAYER_NAME_NORTH;
    case 4: return constants.PLAYER_NAME_EAST;
    default: break;
  }
};

export default gameMiddleware;
