export enum EAppActionTypes {
  APP_IS_MOBILE = "panweb/gameStage/APP_IS_MOBILE",
  APP_MOBILE_SLIDE = "panweb/gameStage/APP_MOBILE_SLIDE",
  APP_IDLE = "panweb/gameStage/APP_IDLE",
  APP_FINISH_DIALOG = "panweb/gameStage/APP_FINISH_DIALOG",
}

export interface IAppState {
  isMobile: boolean;
  mobileSlide: boolean;
  idle: boolean;
  finishDialog: boolean;
  finishDialogTitle: string;
  finishDialogInfo1: string;
  finishDialogInfo2: string;
}

export interface IAppIsMobileAction {
  type: typeof EAppActionTypes.APP_IS_MOBILE;
  isMobile: boolean;
}

export interface IAppMobileSlideAction {
  type: typeof EAppActionTypes.APP_MOBILE_SLIDE;
  mobileSlide: boolean;
}

export interface IAppIdleAction {
  type: typeof EAppActionTypes.APP_IDLE;
  idle: boolean;
}

export interface IAppFinishDialogAction {
  type: typeof EAppActionTypes.APP_FINISH_DIALOG;
  finishDialog: boolean;
  finishDialogTitle: string;
  finishDialogInfo1: string;
  finishDialogInfo2: string;
}

export type TAppAction = IAppIsMobileAction | IAppMobileSlideAction | IAppIdleAction | IAppFinishDialogAction;
