import { Button, Link } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React from "react";
import { IMG_NOKADASOFT, URL_NOKADASOFT } from "../../common/constants";
import { EGameStage } from "../../redux/modules/gameTypes";
import { EWebSocketStateTypes } from "../../redux/modules/webSocketTypes";
import "./status.scss";
import { TComponentProps } from "./StatusTypes";

class Status extends React.Component<TComponentProps> {

  public handleClick = () => {
    const { gameStart, gameQuit, mobileSlide, setMobileSlideOff } = this.props;
    if (this.props.gameStage === EGameStage.STOPPED) {
      gameStart();
      if (mobileSlide) { setMobileSlideOff(); }
    } else {
      gameQuit();
    }
  }

  public render() {
    // https://htmlcolorcodes.com/color-names/
    var connected = false;
    var backgroundColor = "FireBrick";
    var textColor = "White";
    var text = "Disconnected";
    switch (this.props.serverConnectionStatus) {
      case EWebSocketStateTypes.WEBSOCKET_STATE_CONNECTING:
        backgroundColor = "Sienna";
        textColor = "White";
        text = "Connecting (~60s)";
        break;
      case EWebSocketStateTypes.WEBSOCKET_STATE_CONNECTED:
        backgroundColor = "Lime";
        textColor = "Black";
        text = this.props.gameStage !== EGameStage.STOPPED ? "STOP GAME" : "START GAME";
        connected = true;
        break;
    }

    let startEnabledStyle = {};
    if (!this.props.startEnabled || !connected) {
      startEnabledStyle = { pointerEvents: "none" };
    }

    const buttonStyle = {
      width: "100%",
      height: "40px",
      padding: "0px",
      margin: "0px 0px 5px 0px",
      backgroundColor: backgroundColor,
      textColor: textColor,
      ...startEnabledStyle,
    };

    const logoImage = require("../../assets/" + IMG_NOKADASOFT);

    return (
      <Paper elevation={5}>
        <div className="status">
          <Button
            variant="contained"
            onClick={this.handleClick}
            style={buttonStyle}
          >
            {text}
          </Button>
          <br />
          <Link href={URL_NOKADASOFT}>
            <img src={logoImage} alt="logo" style={{ margin: "20px 0 0 0" }} />
          </Link>
        </div>
      </Paper>
    );
  }
}

export default Status;
