// import { Component, Not } from "ecsy";
import PositionComponent from "../components/positionComponent";

export const removeComponentForcedNative = (entity, component) => {
  try {
    entity.removeComponent(component, true);
  } catch (error) {
    console.warn("removeComponentForcedNative error:");
    console.warn(error);
  }
};

export const removeAllEntitiesNative = (world) => {
  // world.stats();
  // world.entityManager.removeAllEntities();
  // world.entityManager.processDeferredRemoval();
  const toDelete = [...queryComponentsNative(world, [PositionComponent])];
  toDelete.forEach((entity) => entity.remove(true));
  // world.stats();
};

export const queryComponentsNative = (world, components) => {
  return world.entityManager.queryComponents(components).entities;
};

// world.getSystem(SystemClass);
// world.getSystems()

// world.entityManager.removeAllEntities();
// world.entityManager.removeEntity(entity, immediately);
// world.entityManager.count(); // all entities in the system count
// world.entityManager.queryComponents(Components); // i.e. queryComponents([PlayerSouthComponent])

// entity.remove(forceRemove);
