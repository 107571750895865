import NotificationSystem from "react-notification-system";

export enum EActionTypes {
  NOTIFICATIONS_SHOW = "panweb/notifications/NOTIFICATIONS_SHOW",
  NOTIFICATIONS_HIDE = "panweb/notifications/NOTIFICATIONS_HIDE",
  NOTIFICATIONS_CLEAR_ALL = "panweb/notifications/NOTIFICATIONS_CLEAR_ALL",
}

export interface INotificationPayload extends NotificationSystem.Notification { }

export interface INotificationsShowAction {
  type: typeof EActionTypes.NOTIFICATIONS_SHOW;
  notification: INotificationPayload;
}
export interface INotificationsHideAction {
  type: typeof EActionTypes.NOTIFICATIONS_HIDE;
  uid: number;
}
export interface INotificationsClearAllAction {
  type: typeof EActionTypes.NOTIFICATIONS_CLEAR_ALL;
}

export type TNotificationsActions = INotificationsShowAction | INotificationsHideAction | INotificationsClearAllAction;
