import { Component, World } from "ecsy";
import AssetsManager from "../managers/assetsManager";
import CollocationManager from "../managers/collocationManager";
import GameStateManager from "../managers/gameStateManager";
import InputControlManager from "../managers/inputControlManager";

export default class ManagersComponent extends Component {
  public world: World;
  public collocationManager: CollocationManager;
  public inputControlManager: InputControlManager;
  public assetsManager: AssetsManager;
  public gameStateManager: GameStateManager;

  constructor(
    world: World,
    collocationManager: CollocationManager,
    inputControlManager: InputControlManager,
    assetsManager: AssetsManager,
    gameStateManager: GameStateManager) {

    super();

    this.world = world;
    this.collocationManager = collocationManager;
    this.inputControlManager = inputControlManager;
    this.assetsManager = assetsManager;
    this.gameStateManager = gameStateManager;
  }

  public reset() { return; }
}
