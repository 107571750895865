import { Checkbox, createStyles, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Theme, withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React, { CSSProperties } from "react";
import * as constants from "../../common/constants";
import * as csSettingsTypes from "../../commonClientServer/settingsTypes";
import { getEffectiveSettings } from "../../redux/modules/settings";
import * as settingsTypes from "../../redux/modules/settingsTypes";
import PlayerInfo from "../PlayerInfo/PlayerInfo";
import { TComponentProps } from "./SettingsTypes";

const useStyles = (theme: Theme) => createStyles({
  root: {},
});

class Settings extends React.Component<TComponentProps> {
  constructor(props: TComponentProps) {
    super(props);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handlePlayerChange = this.handlePlayerChange.bind(this);
  }

  public handleSelectChange(event: any) {
    const { target } = event;
    const { name, value } = target;

    const settings = { ...this.props.settings };
    switch (name) {
      case "difficulty":
        settings.difficulty = value;
        break;
      case "spades":
        settings.spades = value;
        break;
      case "sorting":
        settings.sorting = value;
        break;
      case "showOppCards":
        settings.showOppCards = target.checked;
        break;
      case "speed":
        settings.speed = value;
        break;
      case "notifications":
        settings.notifications = target.checked;
        break;
      default:
        alert("invalid option name " + name);
        break;
    }

    this.props.onSetSettings(settings);
  }

  public handlePlayerChange(player: any) {
    // alert(JSON.stringify(player));
    const settings = { ...this.props.settings };
    switch (player.dataKey) {
      case 0: settings.name = player.name; break;
      case 1: settings.player1_kind = player.kind; break;
      case 2: settings.player2_kind = player.kind; break;
      case 3: settings.player3_kind = player.kind; break;
    }
    this.props.onSetSettings(settings);
  }

  public render() {
    const { classes, isLocked, gameSettings } = this.props;

    const settings = getEffectiveSettings(this.props.settings, gameSettings, isLocked);

    const spades = [
      <MenuItem key="0" value={csSettingsTypes.ESpades.OFF}>off</MenuItem>,
      <MenuItem key="1" value={csSettingsTypes.ESpades.P2UP}>2 players or more</MenuItem>,
      <MenuItem key="2" value={csSettingsTypes.ESpades.P3UP}>3 players or more</MenuItem>,
    ];

    const sorting = [
      <MenuItem key="0" value={settingsTypes.ESortCards.NONE}>none</MenuItem>,
      <MenuItem key="1" value={settingsTypes.ESortCards.ASCENDING}>ascending</MenuItem>,
      <MenuItem key="2" value={settingsTypes.ESortCards.DESCENDING}>descending</MenuItem>,
    ];

    const speed = [
      <MenuItem key="0" value={settingsTypes.ESpeed.SLOW}>slow</MenuItem>,
      <MenuItem key="1" value={settingsTypes.ESpeed.MODERATE}>moderate</MenuItem>,
      <MenuItem key="2" value={settingsTypes.ESpeed.FAST}>fast</MenuItem>,
    ];

    // https://codesandbox.io/embed/0x5mqr4yow
    const lockedStyle: CSSProperties = { pointerEvents: "none", opacity: "0.4" };

    return (
      <Paper elevation={5}>
        <div className="settings">
          <div style={isLocked ? { ...lockedStyle } : {}}>

            {/* PLAYERS */}
            <PlayerInfo key={0} dataKey={0} kind={csSettingsTypes.EKind.HUMAN} name={settings.name} onChange={this.handlePlayerChange} />
            <br />
            <PlayerInfo key={1} dataKey={1} kind={settings.player1_kind} onChange={this.handlePlayerChange} name={constants.PLAYER_NAME_WEST} />
            <PlayerInfo key={2} dataKey={2} kind={settings.player2_kind} onChange={this.handlePlayerChange} name={constants.PLAYER_NAME_NORTH} />
            <PlayerInfo key={3} dataKey={3} kind={settings.player3_kind} onChange={this.handlePlayerChange} name={constants.PLAYER_NAME_EAST} />
            <br />

            {/* <FormControl style={{ minWidth: "100%" }}>
              <InputLabel id="demo-simple-select-label">Difficulty</InputLabel>
              <Select
                name="difficulty"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={defDifficulty}
                onChange={this.handleSelectChange}
              >
                {difficulty}
              </Select>
            </FormControl>
            <br /> */}

            {/* SPADES */}
            <FormControl style={{ minWidth: "100%" }}>
              <InputLabel id="demo-simple-select-label2">Reversing Spades</InputLabel>
              <Select
                name="spades"
                labelId="demo-simple-select-label2"
                id="demo-simple-select2"
                value={settings.spades}
                onChange={this.handleSelectChange}
              >
                {spades}
              </Select>
            </FormControl>
            <br />

            {/* SHOW OPP CARDS */}
            <FormControlLabel style={{ margin: "10px 0 0 0", display: "flex" }}
              control={
                <Checkbox name="showOppCards" className={classes.root} checked={settings.showOppCards} onChange={this.handleSelectChange} value="showOppCards" />
              }
              label="Show opponents cards"
            />
            <br />
          </div>

          {/* SORTING */}
          <FormControl style={{ minWidth: "100%" }}>
            <InputLabel id="demo-simple-select-label">Sorting</InputLabel>
            <Select
              name="sorting"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={settings.sorting}
              onChange={this.handleSelectChange}
            >
              {sorting}
            </Select>
          </FormControl>
          <br />

          {/* SPEED */}
          <FormControl style={{ minWidth: "100%" }}>
            <InputLabel id="demo-simple-select-label">Speed</InputLabel>
            <Select
              name="speed"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={settings.speed}
              onChange={this.handleSelectChange}
            >
              {speed}
            </Select>
          </FormControl>
          <br />

          {/* NOTIFICATIONS */}
          <FormControlLabel style={{ margin: "10px 0 0 0", display: "flex" }}
            control={
              <Checkbox name="notifications" className={classes.root} checked={settings.notifications} onChange={this.handleSelectChange} value="notifications" />
            }
            label="Notifications"
          />
        </div>
      </Paper>
    );
  }
}

export default withStyles(useStyles)(Settings);
