import { NOTIFICATIONS_ERROR_DURATION, NOTIFICATIONS_INFO_DURATION } from "../common/constants";
import { INotificationPayload } from "../redux/modules/notificationsTypes";

export const makeNotificationOnGameAction = (message: string): INotificationPayload => {
  return {
    message,
    level: "success",
    position: "tr",
    autoDismiss: NOTIFICATIONS_INFO_DURATION,
  };
};

export const makeNotificationOnError = (message: string): INotificationPayload => {
  return {
    message,
    level: "error",
    position: "tc",
    autoDismiss: NOTIFICATIONS_ERROR_DURATION,
  };
};
