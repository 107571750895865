import { Entity } from "ecsy";
import { sortEntities } from "../common/utils";
import AssetComponent from "../components/assetComponent";
import CardComponent from "../components/cardComponent";
import InteractiveComponent from "../components/interactiveComponent";
import ManagersComponent from "../components/managersComponent";
import PositionComponent from "../components/positionComponent";

export const rendererSystemExecute = (common: ManagersComponent, qrRenderable: Entity[]) => {
  const ctx = common.collocationManager.ctx;
  const ctxHit = common.collocationManager.ctxHit;

  const cardSize = common.collocationManager.getCardSize(0);

  // clear screen
  ctx.setTransform(1, 0, 0, 1, 0, 0);
  ctxHit.setTransform(1, 0, 0, 1, 0, 0);
  // ctx.globalAlpha = 0.5;
  ctx.clearRect(0, 0, common.collocationManager.screenWidth, common.collocationManager.screenHeight);
  ctxHit.clearRect(0, 0, common.collocationManager.screenWidth, common.collocationManager.screenHeight);

  // ctx.fillStyle = "rgba(250,250,250, 0.5)";
  // ctx.fillRect(5, 5, common.collocationManager.screenWidth - 10, common.collocationManager.screenHeight - 10);

  // sort entities by zIndex ascending
  const entities = sortEntities(qrRenderable);

  // todo: bug tracing - remove after a while
  const colorIndexes: number[] = [];
  for (let i = 0; i < entities.length; i++) {
    const interactive = entities[i].entity.getComponent(InteractiveComponent);
    if (interactive) {
      colorIndexes.push(interactive.colorIndex);
    }
  }
  colorIndexes.forEach((c1) => {
    const count = colorIndexes.filter((c2) => c2 === c1).length;
    if (count > 1) {
      console.error("double colorIndex for " + c1 + " (lenght: " + count);
    }
  });

  // draw entities
  for (let i = 0; i < entities.length; i++) {
    const entity = entities[i].entity;
    const positionComponent = entities[i].positionComponent;

    if (entity.hasComponent(CardComponent)) {
      const cardComponent = entity.getComponent(CardComponent);
      const asset = common.assetsManager.assets[cardComponent.asset]; // Math.floor(Math.random() * 25)];
      drawCard(ctx, asset, positionComponent, cardSize);

      const interactive = entity.getComponent(InteractiveComponent);
      if (interactive) {
        drawCardInteractive(ctxHit, interactive.colorIndex, positionComponent, cardSize);
      }
      continue;
    } else if (entity.hasComponent(AssetComponent)) {
      const assetComponent = entity.getComponent(AssetComponent);
      const asset = common.assetsManager.assets[assetComponent.asset]; // Math.floor(Math.random() * 25)];
      drawAsset(ctx, asset, positionComponent, 1 / common.collocationManager.getRatio());

      const interactive = entity.getComponent(InteractiveComponent);
      if (interactive) {
        drawAssetInteractive(ctxHit, interactive.colorIndex, asset, positionComponent, 1 / common.collocationManager.getRatio());
      }
      continue;
    }

    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctxHit.setTransform(1, 0, 0, 1, 0, 0);
  }

  // finish rendering
  // ctx.setTransform(1,0,0,1,0,0);
  // ctxHit.setTransform(1,0,0,1,0,0);
};

const drawCard = (ctx: any, asset: HTMLImageElement, positionComponent: PositionComponent, size: { w: number, h: number }) => {
  ctx.setTransform(1, 0, 0, 1, positionComponent.x, positionComponent.y);
  ctx.rotate(positionComponent.angle);
  ctx.drawImage(asset, - (size.w / 2), - (size.h / 2), size.w, size.h);
};

const drawCardInteractive = (ctxHit: any, colorIndex: number, positionComponent: PositionComponent, size: { w: number, h: number }) => {
  ctxHit.beginPath();
  ctxHit.fillStyle = `rgb(${colorIndex},0,0)`;
  ctxHit.setTransform(1, 0, 0, 1, positionComponent.x, positionComponent.y);
  ctxHit.rotate(positionComponent.angle);
  ctxHit.rect(- (size.w / 2), - (size.h / 2), size.w, size.h);
  ctxHit.fill();
};

const drawAsset = (ctx: any, asset: HTMLImageElement, positionComponent: PositionComponent, scaleRatio: number) => {
  const w = asset.width * scaleRatio;
  const h = asset.height * scaleRatio;

  ctx.setTransform(1, 0, 0, 1, positionComponent.x, positionComponent.y);
  ctx.rotate(positionComponent.angle);
  ctx.drawImage(asset, - (w / 2), - (h / 2), w, h);
};

const drawAssetInteractive = (ctxHit: any, colorIndex: number, asset: HTMLImageElement, positionComponent: PositionComponent, scaleRatio: number) => {
  const w = asset.width * scaleRatio;
  const h = asset.height * scaleRatio;

  ctxHit.beginPath();
  ctxHit.fillStyle = `rgb(${colorIndex},0,0)`;
  ctxHit.setTransform(1, 0, 0, 1, positionComponent.x, positionComponent.y);
  ctxHit.rotate(positionComponent.angle);
  ctxHit.rect(- (w / 2), - (h / 2), w, h);
  ctxHit.fill();
};
