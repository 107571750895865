import { EStopReason } from "../../common/messagesCacheTypes";
import { EGameActionTypes, EGameStage, IGameServerMoveAction, IGameServerStartAction, IGameSettings, IGameState, TGameAction } from "./gameTypes";

export const GAME_INITIAL_STATE: IGameState = {
  stage: EGameStage.STOPPED, // 0: stopped, 1:awaiting, 2:score
  gameId: undefined,
  nextPlayer: -1,
  settings: undefined,
};

const reducer = (state: IGameState = GAME_INITIAL_STATE, action: TGameAction) => {
  switch (action.type) {

    case EGameActionTypes.GAME_SERVER_START: {
      const a = action as IGameServerStartAction;
      return {
        stage: EGameStage.AWAITING,
        gameId: a.gameId,
        nextPlayer: a.nextPlayer,
        settings: a.settings,
      };
    }

    case EGameActionTypes.GAME_SERVER_STOP: {
      return {
        stage: EGameStage.STOPPED,
        gameId: undefined,
        nextPlayer: undefined,
        settings: undefined,
      };
    }

    case EGameActionTypes.GAME_SERVER_MOVE: {
      const a = action as IGameServerMoveAction;
      return {
        ...state,
        nextPlayer: a.nextPlayer,
      };
    }

    case EGameActionTypes.GAME_USER_START: return state;
    // case EGameActionTypes.GAME_USER_START: {
    //   return {
    //     stage: EGameStage.AWAITING,
    //   };
    // }

    case EGameActionTypes.GAME_USER_STOP:
      return {
        stage: EGameStage.STOPPED,
        gameId: undefined,
        nextPlayer: undefined,
        settings: undefined,
      };

    case EGameActionTypes.GAME_USER_MOVE: return state;

    case EGameActionTypes.GAME_NEXT_PLAYER:
      return {
        ...state,
        nextPlayer: undefined,
      };

    default:
      return state;
  }
};

export default reducer;

export const gameServerStartAction = (gameId: string, nextPlayer: number, settings: IGameSettings): TGameAction =>
  ({ type: EGameActionTypes.GAME_SERVER_START, gameId, settings, nextPlayer });

export const gameServerStopAction = (who: number, why: EStopReason, losers?: number[]): TGameAction =>
  ({ type: EGameActionTypes.GAME_SERVER_STOP, who, why, losers });

export const gameServerMoveAction = (player: number, taking: boolean, cards: string[], nextPlayer: number): TGameAction =>
  ({ type: EGameActionTypes.GAME_SERVER_MOVE, player, taking, cards, nextPlayer });

export const gameUserStartAction = (): TGameAction =>
  ({ type: EGameActionTypes.GAME_USER_START });

export const gameUserStopAction = (): TGameAction =>
  ({ type: EGameActionTypes.GAME_USER_STOP });

export const gameUserMoveAction = (taking: boolean, cards: string[]): TGameAction =>
  ({ type: EGameActionTypes.GAME_USER_MOVE, taking, cards });

export const gameNextPlayerAction = (nextPlayer: number): TGameAction =>
  ({ type: EGameActionTypes.GAME_NEXT_PLAYER, nextPlayer });
