import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import Slide, { SlideProps } from "@material-ui/core/Slide";
import React from "react";
import { CANVAS_SCALE_FACTOR_MOBILE, PAGE_BOARD_SIZE } from "../../common/constants";
import InputHandler from "../../ecs/common/inputHandler";
import GameWorld from "../../ecs/gameWorld";
import AssetsManager from "../../ecs/managers/assetsManager";
import GameStateManager from "../../ecs/managers/gameStateManager";
import { TComponentProps } from "./BoardTypes";

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

export class Board extends React.Component<TComponentProps, { width: number, height: number }> {
  private canvasRef: any = React.createRef();
  private rAF: number = -1;
  // private gameStopped = true;
  private frameDuration = 1000 / 30;
  private lastAnimate: number = 0;
  private gameWorld?: GameWorld;
  private gameStateManager?: GameStateManager;

  private inputHandler: InputHandler | null = null;

  constructor(props: TComponentProps) {
    super(props);
    if (props.isMobile) {
      this.state = { width: 0, height: 0 };
    } else {
      this.state = { width: PAGE_BOARD_SIZE, height: PAGE_BOARD_SIZE };
    }
  }

  public updateSize = (): { width: number, height: number } => {
    console.log("updateSize");
    console.log(this.state);
    const { width, height } = this.state;
    const rect = this.canvasRef.current.getBoundingClientRect();
    if (width !== rect.width || height !== rect.height) {
      this.setState({ width: rect.width, height: rect.height });
    }
    console.log(rect);
    return { width: rect.width, height: rect.height };
  }

  public componentDidMount = () => {
    // console.log("componentDidMount");

    let w: number;
    let h: number;
    if (this.props.isMobile) {
      const { width, height } = this.updateSize();
      w = width * CANVAS_SCALE_FACTOR_MOBILE;
      h = height * CANVAS_SCALE_FACTOR_MOBILE;
    } else {
      const { width, height } = this.state;
      w = width;
      h = height;
    }

    console.log("new AssetsManager: " + w + " " + h);
    const assetsManager = new AssetsManager(w, h);
    assetsManager.init(() => {
      this.start(assetsManager);
    });
  }

  public componentDidUpdate = () => {
    // console.log("componentDidUpdate");
    if (this.gameStateManager) {
      this.gameStateManager.gameSettings = this.props.gameSettings;
    }
    // console.log("GAME STOPPED ???");
    // console.log(this.gameStopped);
    // console.log(this.props.gameStage);
    // if (this.props.gameStage !== EGameStage.STOPPED && this.gameStopped) {
    //   this.gameStopped = false;
    //   this.lastAnimate = performance.now();
    //   this.animate();
    // }
  }

  public start = (assetsManager: any) => {
    const canvas = this.canvasRef.current;
    // console.log("viewport size [" + canvas.width + ", " + canvas.height + "]");

    this.gameStateManager = new GameStateManager(
      this.props.gameSettings,
      this.props.serverGameStart,
      this.props.serverGameStop,
      this.props.serverGameMove,
      this.props.userGameMove,
      this.props.userGameMoveNextPlayer);

    const canvasHit = document.createElement("canvas");
    this.gameWorld = new GameWorld(canvas, canvasHit, assetsManager, this.gameStateManager, this.props.isMobile);

    if (this.props.isMobile) {
      window.addEventListener("resize", () => {
        const { width, height } = this.updateSize();
        const w = width * CANVAS_SCALE_FACTOR_MOBILE;
        const h = height * CANVAS_SCALE_FACTOR_MOBILE;

        const hitCanvas = document.createElement("canvas");
        hitCanvas.width = w;
        hitCanvas.height = h;

        this.gameWorld!.onResize(w, h, hitCanvas.getContext("2d"));
        this.forceUpdate();
      }, false);
    }

    this.inputHandler = new InputHandler(
      this.props.isMobile ? CANVAS_SCALE_FACTOR_MOBILE : 1,
      canvas,
      canvas, // window,
      (x: number, y: number): boolean => {
        this.gameWorld!.onInput(x, y, true);
        return true;
      },
      (x: number, y: number) => {
        this.gameWorld!.onInput(x, y, true);
      },
      () => {
        this.gameWorld!.onInput(0, 0, false);
      });

    // this.gameStopped = false;
    this.lastAnimate = performance.now();
    this.animate();
  }

  public componentWillUnmount = () => {
    console.log("componentWillUnmount");
    if (this.gameWorld) {
      this.gameWorld.clear();
      this.gameWorld = undefined;
    }
    cancelAnimationFrame(this.rAF);

    if (this.inputHandler) {
      this.inputHandler.unregisterEvents();
    }
  }

  public animate = () => {
    // if (this.props.gameStage === EGameStage.STOPPED) {
    //   console.log("GAME STOPPED >>>>");
    //   this.gameStopped = true;
    //   return;
    // }

    const diff = performance.now() - this.lastAnimate;
    if (diff < this.frameDuration) {
      setTimeout(() => {
        this.lastAnimate = performance.now();
        this.gameWorld!.step();
        this.rAF = requestAnimationFrame(this.animate);
      }, this.frameDuration - diff);
    } else {
      this.lastAnimate = performance.now();
      this.gameWorld!.step();
      this.rAF = requestAnimationFrame(this.animate);
    }
  }

  public handleFinishDialogClose = () => { this.props.setFinishDialogOff(); };

  public render() {
    const { width, height } = this.state;
    let w = width;
    let h = height;
    if (this.props.isMobile) {
      w = width * CANVAS_SCALE_FACTOR_MOBILE;
      h = height * CANVAS_SCALE_FACTOR_MOBILE;
    }
    const { finishDialog, finishDialogTitle, finishDialogInfo1, finishDialogInfo2 } = this.props;

    let className = "board-canvas";
    if (this.props.isMobile) {
      className = "mobile-board-canvas";
    }

    return <div className={className} >
      <canvas ref={this.canvasRef} width={w} height={h} className={className} >
        Sorry, your browser doesn't support canvas feature
      </canvas>
      <Dialog
        open={finishDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleFinishDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{finishDialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {finishDialogInfo1}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            {finishDialogInfo2}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleFinishDialogClose} color="primary">
            OK
        </Button>
        </DialogActions>
      </Dialog>
    </div>;
  }
}
