import { EStopReason } from "../../commonClientServer/messagesServerTypes";
import * as csSettingsTypes from "../../commonClientServer/settingsTypes";

export enum EGameStage {
  STOPPED = "GAME_STOPPED",
  AWAITING = "GAME_AWAITING",
  SCORE = "GAME_SCORE",
}

export enum EGameActionTypes {
  GAME_SERVER_START = "panweb/gameStage/GAME_SERVER_START",
  GAME_SERVER_STOP = "panweb/gameStage/GAME_SERVER_STOP",
  GAME_SERVER_MOVE = "panweb/gameStage/GAME_SERVER_MOVE",
  GAME_USER_START = "panweb/gameStage/GAME_USER_START",
  GAME_USER_STOP = "panweb/gameStage/GAME_USER_STOP",
  GAME_USER_MOVE = "panweb/gameStage/GAME_USER_MOVE",
  GAME_NEXT_PLAYER = "panweb/gameStage/GAME_NEXT_PLAYER",
}

export interface IGamePlayer {
  clientId?: string;
  kind: csSettingsTypes.EKind;
}

export interface IGameSettings {
  difficulty: csSettingsTypes.EDifficulty;
  spades: csSettingsTypes.ESpades;
  showOppCards: boolean;
  players: IGamePlayer[];
}

export interface IGameState {
  stage: EGameStage; // 0: stopped, 1:awaiting, 2:score
  gameId?: string;
  settings?: IGameSettings;
  nextPlayer?: number;
}

export interface IGameServerStartAction {
  type: typeof EGameActionTypes.GAME_SERVER_START;
  gameId: string;
  settings: IGameSettings;
  nextPlayer: number;
}
export interface IGameServerStopAction {
  type: typeof EGameActionTypes.GAME_SERVER_STOP;
  who: number;
  why: EStopReason;
  losers?: number[];
}
export interface IGameServerMoveAction {
  type: typeof EGameActionTypes.GAME_SERVER_MOVE;
  player: number;
  taking: boolean;
  cards: string[];
  nextPlayer: number;
}
export interface IGameUserStartAction {
  type: typeof EGameActionTypes.GAME_USER_START;
}
export interface IGameUserStopAction {
  type: typeof EGameActionTypes.GAME_USER_STOP;
}
export interface IGameUserMoveAction {
  type: typeof EGameActionTypes.GAME_USER_MOVE;
  taking: boolean;
  cards: string[];
}

export interface IGameNextPlayerAction {
  type: typeof EGameActionTypes.GAME_NEXT_PLAYER;
  nextPlayer: number;
}

export type TGameAction = IGameServerStartAction | IGameServerStopAction | IGameServerMoveAction |
  IGameUserStartAction | IGameUserStopAction | IGameUserMoveAction | IGameNextPlayerAction;
