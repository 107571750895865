import React from "react";
import { TComponentProps } from "./WebSocketConnectionTypes";

export class WebSocketConnection extends React.Component<TComponentProps> {

  public componentDidMount() {
    const { webSocketConnect } = this.props;
    webSocketConnect();
  }

  // public componentDidUpdate() {
  //   console.log("WebSocketConnection - componentDidUpdate");
  //   const { webSocketConnect } = this.props;
  //   // add to the list
  // }

  public componentWillUnmount() {
    const { webSocketDisconnect } = this.props;
    webSocketDisconnect();
  }

  // public shouldComponentUpdate(nextProps: TComponentProps) { // , nextState) {
  //   console.log("WebSocketConnection - shouldComponentUpdate");
  //   return (nextProps.children !== this.props.children);
  // }

  public render() {
    return <div>{this.props.children}</div>;
  }
}
