import { Dispatch } from "redux";
import * as messagesCache from "../../common/messagesCache";
import { ICacheMessageMove } from "../../common/messagesCacheTypes";
import * as utils from "../../common/utils";
import * as healthcheck from "../../commonClientServer/healthcheck";
import { EServerMessageType, IServerMessageError, TServerMessage } from "../../commonClientServer/messagesServerTypes";
import * as notificationsActions from "../modules/notifications";
import * as webSocketActions from "../modules/webSocket";
import * as webSocketTypes from "../modules/webSocketTypes";
import { IStore } from "../storeTypes";

const webSocketMiddleware = () => {
  let socket: WebSocket | null = null;
  let socketServerDownTimeout: NodeJS.Timeout | null = null;
  const socketServerDownTimeoutClear = () => { if (socketServerDownTimeout) { clearTimeout(socketServerDownTimeout); socketServerDownTimeout = null; } };
  let socketReconnectTimeout: NodeJS.Timeout | null = null;
  const socketReconnectTimeoutClear = () => { if (socketReconnectTimeout) { clearTimeout(socketReconnectTimeout); socketReconnectTimeout = null; } };

  // RESET SERVER DOWN CHECK
  const resetServerDown = (store: IStore): void => {
    socketServerDownTimeoutClear();
    socketServerDownTimeout = setTimeout(() => {
      store.dispatch(webSocketActions.webSocketDisconnect());
      store.dispatch(webSocketActions.webSocketConnect());
    }, healthcheck.CLIENT_MAX_RESPONSE_TIME);
  };

  const onOpen = (ev: Event, store: IStore) => {
    console.log("webSocketMiddleware - onOpen");
    // await (new Promise((resolve, reject) => { setTimeout(() => { resolve(); }, 2000); }));
    store.dispatch(webSocketActions.webSocketConnected()); // event.target.url));
    resetServerDown(store);
  };

  const onClose = (ev: CloseEvent, store: IStore) => {
    console.log("webSocketMiddleware - onClose");
    socketServerDownTimeoutClear();
    socketReconnectTimeoutClear();
    socket = null;

    if (store.getState().webSocket.socketState !== webSocketTypes.EWebSocketStateTypes.WEBSOCKET_STATE_DISCONNECTED) {
      store.dispatch(webSocketActions.webSocketDisconnected());
    }

    socketReconnectTimeout = setTimeout(() => {
      if (!socket) {
        store.dispatch(webSocketActions.webSocketConnect());
      } else {
        console.error("cannot start reconnecting - socket already in use");
      }
    }, healthcheck.CLIENT_RECONNECT_DELAY);
  };

  const onError = (ev: Event, store: IStore) => {
    console.log("webSocketMiddleware - onError");
    store.dispatch(notificationsActions.show(utils.makeNotificationOnError("Game Server is not responding - it may take few minutes before it wakes up - retry in progress...")));
  };

  const onMessage = (ev: MessageEvent, store: IStore) => {
    console.log("webSocketMiddleware - onMessage");
    resetServerDown(store);
    if (ev.data === healthcheck.SERVER_PING_DATA) {
      socket!.send(ev.data);
    } else {
      const message = JSON.parse(ev.data) as TServerMessage;
      switch (message.type) {
        case EServerMessageType.CLIENTID:
          store.dispatch(webSocketActions.webSocketClientId(message.payload.clientId));
          break;
        case EServerMessageType.START: {
          const cm = messagesCache.convertServerMessage(message)!;
          messagesCache.addMessageToQueue(cm);
          break;
        }
        case EServerMessageType.STOP: {
          const cm = messagesCache.convertServerMessage(message)!;
          messagesCache.addMessageToQueue(cm);
          break;
        }
        case EServerMessageType.MOVE: {
          const cm = messagesCache.convertServerMessage(message)! as ICacheMessageMove;
          if (cm.player === 0) {
            if (!messagesCache.isEmpty()) {
              console.error("user couldn't perform a move while there are still queued messages to process");
            }
          }
          messagesCache.addMessageToQueue(cm);
          break;
        }
        case EServerMessageType.ERROR:
          const msgError = message as IServerMessageError;
          console.error(msgError.payload.error);
          store.dispatch(notificationsActions.show(utils.makeNotificationOnError(msgError.payload.error)));
          break;
        default:
          console.error(message);
          break;
      }
    }
  };

  // the middleware part of this function
  return (store: any) => (next: Dispatch) => (action: webSocketTypes.TWebSocketActions) => {
    switch (action.type) {
      // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: CONNECT
      case webSocketTypes.EActionTypes.WEBSOCKET_CONNECT:
        if (socket !== null) { break; }
        socketServerDownTimeoutClear();
        socketReconnectTimeoutClear();

        // connect to the remote host
        // https://saule1508.github.io/create-react-app-proxy-websocket/
        // https://stackoverflow.com/questions/56303511/react-js-problem-with-proxy-for-web-socket-link-in-react

        // const protocolPrefix = window.location.protocol === "https:" ? "wss:" : "ws:";
        // const { host } = window.location; // nb: window location contains the port, so host will be localhost:3000 in dev
        // let port = "";
        // if (process.env.REACT_APP_WEBSOCKET_PORT) {
        //   port = ":" + process.env.REACT_APP_WEBSOCKET_PORT;
        // }
        // const socketUrl = `${protocolPrefix}//` + process.env.REACT_APP_WEBSOCKET_HOST + port;
        const socketUrl: string|URL = process.env.REACT_APP_WEBSOCKET_HOST!; // "wss://nokadasoft-panweb-game.onrender.com";
        console.log("connecting to '" + socketUrl + "'");

        socket = new WebSocket(socketUrl);
        console.log("connected to '" + socketUrl + "'");
        console.log(socket);

        // websocket handlers
        socket.onopen = (ev: Event) => onOpen(ev, store);
        socket.onclose = (ev: CloseEvent) => onClose(ev, store);
        socket.onerror = (ev: Event) => onError(ev, store);
        socket.onmessage = (ev: MessageEvent) => onMessage(ev, store);
        break;

      // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: DISCONNECT
      case webSocketTypes.EActionTypes.WEBSOCKET_DISCONNECT:
        socketServerDownTimeoutClear();
        socketReconnectTimeoutClear();
        if (socket !== null) {
          socket.onclose = null;
          socket.close();
          socket = null;
        }

        // well this line is executed immediately even before the middleware finishes and disconnect reducer is setting state
        // so disconnected is set now
        // then middleware finishes and sets disconnecting... this is wrong
        // https://stackoverflow.com/questions/51186205/how-to-dispatch-actions-from-redux-middleware-in-correct-order
        // store.dispatch(webSocketActions.disconnected());
        break;

      // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: SEND MESSAGE
      case webSocketTypes.EActionTypes.WEBSOCKET_SEND_MESSAGE:
        const actionSendMessage = action as webSocketTypes.IWebSocketSendMessageAction;
        if (socket && socket.readyState === socket.OPEN) {
          socket!.send(JSON.stringify(actionSendMessage.message));

          console.warn("WEBSOCKET_SEND_MESSAGE:");
          console.warn(actionSendMessage.message);
        } else {
          console.error("unable to send message - socket disconnected");
        }
        break;

      default: break;
    }

    return next(action);
  };
};

export default webSocketMiddleware();
