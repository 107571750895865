import { connect } from "react-redux";
import { Dispatch } from "redux";
import { webSocketConnect, webSocketDisconnect } from "../../redux/modules/webSocket";
import { IStoreState } from "../../redux/storeTypes";
import { WebSocketConnection } from "./WebSocketConnection";
import { IMapDispatchToProps, IMapStateToProps, IOwnProps } from "./WebSocketConnectionTypes";

const mapStateToProps = (state: IStoreState, ownProps: IOwnProps): IMapStateToProps => ({
  ...ownProps,
});

const mapDispatchToProps = (dispatch: Dispatch): IMapDispatchToProps => ({
  webSocketConnect: () => dispatch(webSocketConnect()),
  webSocketDisconnect: () => dispatch(webSocketDisconnect()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebSocketConnection);
