export default class InputHandler {
  private scaleFactor: number;
  private canvas: any;
  private listener: any;

  private onSelectCallback: (x: number, y: number) => boolean;
  private onDragCallback: (x: number, y: number) => void;
  private onDropCallback: () => void;

  constructor(
    scaleFactor: number,
    canvas: any,
    listener: any,
    onSelectCallback: (x: number, y: number) => boolean,
    onDragCallback: (x: number, y: number) => void,
    onDropCallback: () => void) {

    this.scaleFactor = scaleFactor;
    this.canvas = canvas;
    this.listener = listener;
    this.onSelectCallback = onSelectCallback;
    this.onDragCallback = onDragCallback;
    this.onDropCallback = onDropCallback;

    this.registerEvents();
  }

  public registerEvents = () => {
    this.listener.addEventListener("mousedown", this.onMouseDown, false);
    this.listener.addEventListener("touchstart", this.onTouchStart, false);
  }

  public unregisterEvents = () => {
    this.listener.removeEventListener("mousedown", this.onMouseDown);
    this.listener.removeEventListener("mousemove", this.onMouseMove);
    this.listener.removeEventListener("mouseup", this.onMouseUp);
    this.listener.removeEventListener("touchstart", this.onTouchStart);
    this.listener.removeEventListener("touchmove", this.onTouchMove);
    this.listener.removeEventListener("touchend", this.onTouchEnd);
  }

  // ................................................. common
  private getXYforClick = (event: any) => {
    // const x = event.pageX - canvas.offsetLeft;
    // const y = event.pageY - canvas.offsetTop;
    // const x = event.clientX - canvas.offsetLeft;
    // const y = event.clientY - canvas.offsetTop;
    const rect = this.canvas.getBoundingClientRect();
    return {
      x: (event.clientX - rect.left) * this.scaleFactor,
      y: (event.clientY - rect.top) * this.scaleFactor,
    };
  }

  private getXYforTouch = (touch: any) => {
    const rect = this.canvas.getBoundingClientRect();
    return {
      x: (touch.clientX - rect.left) * this.scaleFactor,
      y: (touch.clientY - rect.top) * this.scaleFactor,
    };
  }

  // ................................................. MOUSE
  private onMouseDown = (event: any) => {
    if (event.button !== 0) { return; }
    event.preventDefault();
    const coords = this.getXYforClick(event);
    if (this.onSelectCallback(coords.x, coords.y)) {
      this.listener.addEventListener("mousemove", this.onMouseMove, false);
      this.listener.addEventListener("mouseup", this.onMouseUp, false);
    }
  }

  private onMouseMove = (event: any) => {
    event.preventDefault();
    const coords = this.getXYforClick(event);
    this.onDragCallback(coords.x, coords.y);
  }

  private onMouseUp = (event: any) => {
    if (event.button !== 0) { return; }
    event.preventDefault();
    this.listener.removeEventListener("mousemove", this.onMouseMove);
    this.listener.removeEventListener("mouseup", this.onMouseUp);
    this.onDropCallback();
  }

  // ................................................. TOUCH
  private onTouchStart = (event: any) => {
    const touch = event.touches[0];
    const coords = this.getXYforTouch(touch);
    if (this.onSelectCallback(coords.x, coords.y)) {
      this.listener.addEventListener("touchmove", this.onTouchMove, false);
      this.listener.addEventListener("touchend", this.onTouchEnd, false);
    }
  }

  private onTouchMove = (event: any) => {
    event.preventDefault();
    const touch = event.touches[0];
    const coords = this.getXYforTouch(touch);
    this.onDragCallback(coords.x, coords.y);
  }

  private onTouchEnd = (event: any) => {
    event.preventDefault();
    this.listener.removeEventListener("touchmove", this.onTouchMove);
    this.listener.removeEventListener("touchend", this.onTouchEnd);
    this.onDropCallback();
  }

}
