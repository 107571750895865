import { Component } from "ecsy";

export default class GameCommandComponent extends Component {
  public tween: any;
  public message: string = "";

  public copy(src: GameCommandComponent) {
    this.tween = src.tween;
    this.message = src.message;
  }

  public reset() {
    this.tween = null;
    this.message = "";
  }
}
